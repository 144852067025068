import React from 'react';
import {Link} from 'react-router-dom';
import {Button, Modal} from 'react-bootstrap';
import upVoteIcon from './img/favorite_border.png';
import votedIcon from './img/favorite.png';
import {ClipLoader} from 'react-spinners';
import { VoteSlider } from './voteSlider';
import { setVoteWeight, urlSettings, voteWeight } from './constants';
import { votePost } from './apiCalls/votePost';
import { PostVotesView } from './postVotesView';

/**
 * @property {string} className - css class of returned container div
 * @property {boolean} hasVoted - if the user has already voted on this post
 * @property {boolean} loading - if a vote for the post is currently being sent
 * @property {callback} onClick - callback function that happens onClick
 * @property {boolean} voteError - error received when voting
 */
export class VoteButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          hidden: false,
          showSlider: false,
          loading: false,
          viewVotes: false,
          activeVotes: null,
          sliderWeight: 50,
        }
        this.hidePopup = this.hidePopup.bind(this);
        this.clickVote = this.clickVote.bind(this);
        this.hideSlider = this.hideSlider.bind(this);
        this.confirmVote = this.confirmVote.bind(this);
        this.setDefaultVoteWeight = this.setDefaultVoteWeight.bind(this);
    }
    

    hidePopup() {
      this.setState({hidden: true})
    }

    handleVoteSlider = (weight) => {
      this.setState({sliderWeight: weight})
    }

    handleUpVotePost = () => {      
      return new Promise((resolve, reject) => {
        //starts voting
        this.setState({loading: true}, () => {
          if (localStorage.getItem('showSlider') && localStorage.getItem('showSliderInFeed')) {
            var weight = this.state.sliderWeight;
          }
          else {
            var weight = this.props.voteData.hasVoted ? 0 : voteWeight;
          }
          //calls api
          votePost(this.props.voteData.voter, this.props.voteData.author, this.props.voteData.permlink, weight)
          .then(res => {
            if (res.error) {
              reject(res.error);
            }
            //vote finished - changes vote icon if voting was successful
            var activeVotes = this.props.voteData && this.props.voteData.activeVotes ? [...this.props.voteData.activeVotes] : [];
            var hasVoted = this.props.voteData.hasVoted;
            if (hasVoted) {
              if (weight === 0) {
                hasVoted = false;
              }              
              if (activeVotes[0] && activeVotes[0].voter === this.props.voteData.voter) {
                activeVotes[0].percent = parseInt(weight) * 100;
              }
            }
            else {
              //Note: if user has voted and unvoted on a post, then comes back in another session,
              //their name will show up twice in voter list if they vote again
              let voterObject = {
                voter: this.props.voteData.voter,
                percent: weight,
              }
              if (activeVotes[0]) {         
                //updates values if first object is voter       
                if (activeVotes[0].voter === this.props.voteData.voter) {
                  activeVotes[0] = voterObject;
                }
                //else places values first
                else {
                  activeVotes.unshift(voterObject);
                }                
              }
              //places values first if there is no first object
              else {
                activeVotes.unshift(voterObject)
              }
              hasVoted = true;
            }
            var returnData = {
              hasVoted: res? hasVoted : this.props.voteData.hasVoted,
              activeVotes: res? activeVotes : this.props.voteData.activeVotes
            }
            this.setState({loading: false})
            resolve(returnData);
          })
          .catch(err => {
            this.getReadAbleErrorMessage(err)
            .then(res => {
              this.setState({voteError: err, customErrorMessage: res.readable});
            })
            .catch(err => {

            })
            .finally(() => {       
              this.setState({loading: false}) 
              reject(err)
            })
          })
        })
      })
    }

    getReadAbleErrorMessage = (errorObject) => {
      return new Promise((resolve, reject) => {
        let returnObject = {original: errorObject};
        if (errorObject && errorObject.error_description) {
          let description = errorObject.error_description.toLowerCase();
          if (description.includes('can only vote once every')) {
            returnObject.readable = 'You can only vote once every 3 seconds, please try again in a few seconds.';
            resolve(returnObject);
          }
          else if (description.includes('your current vote on this comment is identical to this vote')) {
            returnObject.readable = 'You have already voted on this. If you are intending to change or remove your vote, please refresh the page and try again.';
            resolve(returnObject)
          }
          else if (description.includes('this access_token allow you to broadcast transaction only for the account')) {
            returnObject.readable = 'Failed to authenticate. Please refresh the page and try again. If the issue persists, please try logging out and back in.';
            resolve(returnObject);
          }
          else {
            returnObject.readable = errorObject.error_description;            
            resolve(returnObject)
          }
        }
        else reject(errorObject);
      })
    }

    clickVote() {
      this.setState({hidden: false}, () => {
        if (localStorage.getItem('showSlider') && localStorage.getItem('showSliderInFeed')) {
          this.setState({showSlider: true})
        }
        else {
          this.confirmVote();
        }
      })
    }

    confirmVote() {
      var setDefaultBox = document.getElementById('set-default-weight');
      if (setDefaultBox && setDefaultBox.checked) {
        this.setDefaultVoteWeight();
      }
      var disablePopupBox = document.getElementById('disable-vote-slider');
      if (disablePopupBox && disablePopupBox.checked) {
        localStorage.removeItem('showSliderInFeed');
        this.setState({showSlider: false})
      }
      this.handleUpVotePost()
      .then(res => {
        this.props.handleUpVotePost(res);
      })
      .catch(err => {
        console.log(err)
      })
      this.hideSlider();
    }

    hideSlider() {
      this.setState({showSlider: false})
    }

    setDefaultVoteWeight() {
      var e = document.getElementById('vote-weight-slider');
      if (e) {
        var weight = e.value;
        localStorage.setItem('voteWeight', weight);
        setVoteWeight(weight);
      }
    }

    
  viewVotes = () => {
    this.setState({viewVotes: !this.state.viewVotes});
  }

  render() {
    var activeVotes = this.props.voteData && this.props.voteData.activeVotes ? this.props.voteData.activeVotes : [];
    var voteButton = (
        <Button className='preview-vote-button' onClick={this.clickVote}>
          <img src={this.props.voteData.hasVoted? votedIcon : upVoteIcon} alt='vote icon'/>
        </Button>
      )        

    if (this.state.loading) {
      var spinner = (        
        <ClipLoader 
        color={'#FF8D36'}
        loading={true}
      />
      )
    }        

  if (this.state.viewVotes) {
    var viewVotesWindow = (
      <PostVotesView
        author={this.props.voteData.author}
        permlink={this.props.voteData.permlink}
        votes={[...activeVotes]}
        show={this.state.viewVotes}
        onHide={this.viewVotes}
      />
    )
  }
    return (
      <>
        <div className={this.props.className}>
            {this.state.loading ? spinner : voteButton}              
        </div>              
        <p className='preview-vote-amount' onClick={this.viewVotes} title='View who voted on this post'>
          {activeVotes.length}
        </p>
        {viewVotesWindow}
        <Modal show={this.state.showSlider} onHide={this.hideSlider}>
            <Modal.Header closeButton>
              <Modal.Title>Choose vote weight</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <VoteSlider onChange={this.handleVoteSlider}/>
            <div className='vote-slider-checkbox'>
              <input id='set-default-weight' type='checkbox' />
              <label style={{fontWeight: 'normal'}} htmlFor='set-default-weight'>Use this value as default</label>
              <br/>
              <input id='disable-vote-slider' type='checkbox' />
              <label style={{fontWeight: 'normal'}} htmlFor='disable-vote-slider'>
                Don't show again (you can enable it again in the <Link to={urlSettings}>settings menu</Link>)
              </label>
            </div>                   
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.hideSlider}>Cancel</Button>
              <Button onClick={this.confirmVote}>Vote</Button>
            </Modal.Footer>
          </Modal>
          <Modal show={!!(this.state.voteError && !this.state.hidden)}
            onHide={this.hidePopup} dialogClassName='vote-failed-dialog' centered='true'>
              <Modal.Header>
                  <Modal.Title>Could not vote</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {!localStorage.getItem('loggedIn') || !JSON.parse(localStorage.getItem('loggedIn')) ? <p>You need to log in to vote</p> :
                <div>
                  <p>{this.state.customErrorMessage ? this.state.customErrorMessage : 'Try again later'}</p>
                </div>
                }
              </Modal.Body>
              <Modal.Footer>
                  <Button variant='primary' onClick={this.hidePopup}>Ok</Button>
              </Modal.Footer>
          </Modal>
        </>
      )
    }
}