import steem from 'steem';

/**
 * Returns accounts similar to a provided name
 * @param {string} lowerBoundName returns names similar to this.
 * @param {string | number} limit amount of users returned.
 */
export const lookupAccounts = (lowerBoundName, limit) => {
  return new Promise((resolve, reject) => {
    steem.api.lookupAccounts (
      lowerBoundName,
      limit,
      (err, res) => {
        if (res) {
          resolve(res);
        }
        else {
          reject(err);
        }
      }
    )
  })
}