import React from 'react';
import { Switch, Route } from 'react-router-dom';
import './mainNavBar.css';
import {PostBlog} from './postBlog';
import {ReadPostContainer} from './readPostContainer';
import {Profile} from './profile';
import {HelpRegister} from './HelpRegister.js';
import {HomePage} from './homePage';
import {HelpPage} from './HelpPage';
import './styleTemplates.css';
import './contentPreviewStyle.css';
import './votes.css';
import { SearchResults } from './searchResults';
import { EditProfilePage } from './editProfilePage';
import { NotificationPage } from './notifications';
import { WalletPage } from './walletPage';
import { CompleteSteemconnect } from './completeSteemconnect';
import { LicensePage } from './licensePage';
import { DiscoverPage } from './discover';
import { SettingsPage } from './settingsPage';
import { urlNewPost, urlPost, urlEditProfile, urlProfile, urlSearch, urlTag, urlWallet, urlSettings, urlLicenses, urlNotifications, urlDiscover, getUrlProfile, getUrlTag, urlAppLinks } from './constants';
import { AppLinksPage } from './appLinksPage';
import ScrollToTop from './scrollToTop';

/**
 * Returns a component based on the document url.
 * Props should include feedOptions and a callback function with params feedtype and feedmode
 */
export class MainRouter extends React.Component {
  constructor(props) {
    super(props);
  }

  Home = ({match}) => {
    return <HomePage match = {match} feedOptions={this.props.feedOptions} callback={this.props.callback} />
  }

  post = () => (
    <PostBlog />
  )

  CompleteSc = () => (
    <CompleteSteemconnect />
  );
  
  Hot = ({match}) => {
    match.params.tag = match.params.tag ? match.params.tag : 'deegram';
    return <HomePage match = {match} feedType="hot"  feedOptions={this.props.feedOptions} callback={this.props.callback}/>
  }
  
  Discover = () => (
    <DiscoverPage className='discover-container'/>
  )
  
  Trending = ({match}) => {
    match.params.tag = match.params.tag ? match.params.tag : 'deegram';
    console.log(match.params.tag)
    return <HomePage match = {match} feedType="trending"  feedOptions={this.props.feedOptions} callback={this.props.callback}/>
  }
  
  New = ({match}) => {
    match.params.tag = match.params.tag ? match.params.tag : 'deegram';
    return <HomePage match = {match} feedType="new"  feedOptions={this.props.feedOptions} callback={this.props.callback}/>
  }

  AllTags = ({match}) => {
    match.params.tag = '___';
    return <HomePage match={match} feedType={match.params.type} feedOptions={this.props.feedOptions} callback={this.props.callback}/>
  }
  
  FollowsFeed = ({match}) => (
    <HomePage  match={match} feedOptions={this.props.feedOptions} callback={this.props.callback}/>
  );
  
  Notifications = () => (
    <NotificationPage />
  )
  
  Register = () => (
    <div className = "registrer">
      <HelpRegister/>
    </div>
  )
  
  redirectToProfile = ({match}) => (
    document.location.href = getUrlProfile(match.params.userName)
  )

  appLinks = () => (
    <AppLinksPage/>
  )
  
  profilePage =({match}) => (
    <>
    <Profile match = {match} feedOptions={this.props.feedOptions} callback={this.props.callback}/></>
  )
  
  editProfilePage = ({match}) => (
    <EditProfilePage match = {match}/>
  )
  
  readPost = ({match}) =>(
    <ReadPostContainer match = {match}/>
  )
  
  Help = () =>(
    <HelpPage />
  )
  
  Licenses = () => (
    <LicensePage />
  )
  
  Search = ({match}) => (
    <SearchResults match={match} />
  )
  
  settings = () => (
    <SettingsPage />
  )
  
  tag = ({match}) => (
    <HomePage match={match} feedOptions={this.props.feedOptions} callback={this.props.callback}/>
  )
  
  Wallet = ({match}) => (
    <WalletPage account={match.params.account} />
  )

  redirect = ({match}) => (
    window.location.href=`https://deegram.com`
  )

  render() {
    window.location.href = `https://deegram.com`;
    return (
      <ScrollToTop>
      <Switch>
        <Route component={this.redirect}></Route>
       </Switch>
      </ScrollToTop>
    )
  }
};

export const NoMatch = () => {
  var path = document.location.pathname;
  var words = path.split('/');
  var displayLinks = true;
  if (words.length > 5) displayLinks = false;
  if (displayLinks) {
    var displayTagLink = true;
    var displayProfileLink = true;
    for (var word of words) {
      if (word === 'tag') displayTagLink = false;
      if (word === 'profile') displayProfileLink = false;
    }
  }
  var lastWord = words.pop();

  return (
  <div className= 'padding-top error-404-page'>
    <h2>Error 404 - Page not found</h2>
    <h3>No match for path <code>{path}</code>.</h3>
    {displayLinks ? (<div><h3>Check that the url is correct or try one of the following:</h3>
    <ul>
      <li><a href={getUrlTag(lastWord)}>{getUrlTag(lastWord)}</a></li>
      <li><a href={getUrlProfile(lastWord)}>{getUrlProfile(lastWord)}</a></li>
    </ul>
    </div>) : ''}
  </div>
  )
}
