import steem from 'steem';

/**
 * Returns an array of votes containing information like voter name and weight / percent
 * @param {string} author author of post
 * @param {string} permlink permlink of post
 */
export const getActiveVotes = (author, permlink) => {
  return new Promise((resolve, reject) => {  
    steem.api.getActiveVotes(author, permlink, (err, res) => {
      if (err) {
        reject(err);
      }
      if (res) {
        resolve(res);
      }
    })
  })
}