import React from 'react';
import {Link, Prompt} from 'react-router-dom';
import { Modal, Button, FormControl, FormGroup, ControlLabel, Alert, Popover, OverlayTrigger} from 'react-bootstrap';
import './postBlogForm.css';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import HelpBoxes from './helpBoxes';
import draftToMarkDown from 'draftjs-to-markdown';
import Cookies from 'universal-cookie';
import { ImageCropView } from './imageCropView';
import { deegramFeedApi, getUrlProfile, getSteemAvatar, getUrlPost, urlFeedbackForm, getDeegramImage } from './constants';
import { BarLoader } from 'react-spinners';
import turndown from 'turndown';
import { imageLinkRegex } from './convertBody';
import { ReadPost } from './blogFeed/ReadPost';

/*
Klasse som lar en bruker lage en bloggpost. tar i mot og håndterer input fra
bruker og sender det til en funksjon som håndterer API-kall for å poste inlegget til steemit.
*/
export class PostBlogFormView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false, 
      userName: localStorage.getItem('userName'), 
      title: '', 
      article: '', 
      editorState: EditorState.createEmpty(), 
      tagArray: [],
      loggedIn: JSON.parse(localStorage.getItem('loggedIn')),
      uploading: false,
      imageToUpload: null,
      uploadedImageIds: [],
      postUploaded: false,
      uploadedPostUrl: '',
      requiredFields: '',
      optionalFields: '',
    };

    this.toggleRequiredFieldsPopup = this.toggleRequiredFieldsPopup.bind(this);
    this.toggleOptionalFieldsPopup = this.toggleOptionalFieldsPopup.bind(this);
    this.submitClicked = this.submitClicked.bind(this);
    this.submitPost = this.submitPost.bind(this);
    this.setCroppedImageId = this.setCroppedImageId.bind(this);
    this.prepareJsonString = this.prepareJsonString.bind(this);
    this.turndown = new turndown();
  }


  handleChangeTitle = (e) => {
    this.setState({title: e.target.value});
  }

  handleChangeTags = (e) => {
    //replaces any number of whitespace with a single space character, and removes leading / trailing spaces
    var tagsString = e.target.value.replace(/\s+/g, ' ').trim();
    tagsString = tagsString.replace(/[#,]*/g, '');
    //sets tags to array, or blank string if there are no tags entered
    this.setState({tagArray: tagsString ? (tagsString.split(' ')) : ''});
  }

  /**
   * Updates the editorState object and converts it to html
   * @param {EditorState} editorState content to be converted
   */
  onEditorStateChange = (editorState) => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    var markup = draftToMarkDown(
      rawContentState
    )
    var imageLinkRegex = new RegExp(/\[.*\]\(https*:\/\/((deegram(-test)*\.azurewebsites\.net\/api\/images\/\S+)|([\w\d\.\-_\/:%]+\.(jpg|png|gif)))\)(?=[\s<&])/g);
    markup = markup.replace(imageLinkRegex, '!$&  ')
    this.setState({article: markup, editorState: editorState});
  }

  /**
   * Hides alert for an error
   */
  handleDismiss = () => {
    this.setState({show: false});
  }

  /**
   * Creates the json object with the required data, and returns it as a string
   */
  prepareJsonString() {
    /*
    //images are now only manually entered into a post
    var images = this.state.uploadedImageIds;
    var image = images.length > 0 ? images[images.length -1] : null;
    */
    var title = this.state.title;
    var text = this.state.article;
    var tags = [];
    for (var i = 0; i < this.state.tagArray.length && i < 4; i++) {
      tags.push(this.state.tagArray[i]);
    }
    text = text.replace(imageLinkRegex, '![]($&)')
    var jsonData = {
      //image: image,
      title: title,
      text: text,
      tags: tags
    }
    var jsonString = JSON.stringify(jsonData)
    return jsonString;
  }

  /**
   * Uploads post to the blockchain via deegram server
   */
  submitPost() {
    this.setState({uploading: true, optionalFields: ''})
    const cookies = new Cookies();
    const authToken = cookies.get('token');   
    var jsonString = this.prepareJsonString();

    fetch(deegramFeedApi, {
      method: 'POST',
      body: jsonString,
      headers: {
        'Authorization': authToken,
        'Content-Type': 'application/json'
      }
    })
    .then(res => {
      return res.json()
    })
    .then( res => {
      //console.log(res);
      if (res) {
        if (res.permlink) {
          this.setState({uploadedPostUrl: getUrlPost(this.state.userName, res.permlink), uploading: false})
        }
      }
      else {
        this.setState({uploadFailed: true, uploading: false})
      }

    })
    .catch(error => {
      console.log('Error: ', error);
      this.setState({uploadFailed: true, uploading: false})
    })
  }

  /**
   * Called when user clicks to submit a post; various checks before the post is submitted
   */
  submitClicked() {
    var requiredFields = [];
    if (!this.state.title) requiredFields.push('Title');
    if (requiredFields.length > 0) {
      this.setState({requiredFields});
      return;
    }
    else {
      this.setState({requiredFields: ''})
    }
    var optionalFields = [];
    if (this.state.article.length < 1) optionalFields.push('Body');
    if (this.state.tagArray.length === 0) optionalFields.push('Tags');
    if (optionalFields.length > 0) {
      this.setState({optionalFields});
      return;
    }
    else {
      this.setState({optionalFields: ''})
    }
    this.submitPost();
  }

  setCroppedImageId(id) {
    this.setState({uploadedImageIds: [...this.state.uploadedImageIds, id]});
  }

  toggleRequiredFieldsPopup() {
    this.setState({requiredFields: ''});
  }

  toggleOptionalFieldsPopup() {
    this.setState({optionalFields: ''})
  }

  componentDidUpdate() {
    var hasUpdated = !this.state.uploadedPostUrl;
    if (hasUpdated) {
      window.onbeforeunload = () => true;
    }
    else {
      window.onbeforeunload = undefined;
    }
  }

  componentWillUnmount() {
    window.onbeforeunload = undefined;
  }

  insertImageIntoBody = (e) => {
    try {
      let imageUrl = e.target.src;
      if (imageUrl) {
        const {editorState} = this.state;
        let raw = convertToRaw(editorState.getCurrentContent());
        let markup = draftToMarkDown(raw);
        let text = markup + imageUrl;
        let newEditorState = EditorState.createWithContent(ContentState.createFromText(text));
        this.onEditorStateChange(newEditorState);
      }

    }
    catch (ex) {

    }
  }

  onKeyDown = (e) => {
    if (e.keyCode === 27) {
      e.preventDefault();
    }
  }

  render() {
    const { editorState } = this.state;
    //console.log(this.prepareJsonString());

    //oppretter en alert hvis en error blir returnert av postblogForm
    if (this.state.show){
      var errorAlert = (
        <Alert bsStyle="danger" onDismiss={this.handleDismiss}>
          {this.state.loggedIn ? (
          <div>
            <h4>Oops, something went wrong</h4>
            <p>Please tell us what went wrong, and we will try to fix it: <a href={urlFeedbackForm}>Feedback form</a></p>
          </div>
          ) : (
          <div>
            <h4>Your login session has expired</h4>
            <p>Please log in and try again</p>
          </div>)}
        </Alert>
      )
    }
    if (this.state.uploadedPostUrl) {
      var postUploaded = (
        <div>
          Posted! You can find the post <Link to={this.state.uploadedPostUrl}>here</Link> or on <Link to={getUrlProfile(this.state.userName)}>your profile</Link>
        </div>
      )
    }
    else if (this.state.uploadFailed) {
      var uploadFailed = (
        <div>Upload failed, please try again later</div>
      )
    }

    if (this.state.uploading) {
      var uploadSpinner = (
        <BarLoader color={'#0085cd'} loading={true} height={5} width={300}/>
      )
    }

    if (this.state.tagArray.length > 4) {
      var tagsWarning = (      
        <div className='postblog-tag-warning'>
          Note: you can only use 4 tags; your first 4 will be submitted with the post
        </div>
      )
    }

    if (this.state.article.length > 10) {
      var post = {
        author: this.state.userName,
        permlink: '',
        root_author: this.state.userName,
        root_permlink: '',
        title: this.state.title,
        body: this.state.article,

      }
      var postPreview = (
        <div className='postblog-post-preview'>
          <h2>Post preview:</h2>
          <ReadPost
          isPreview={true}
          post={post}
          tags={this.state.tagArray.slice(0,4)}
          hasVoted={false}
          activeVotes={[]}
          netVotes={0}/>
        </div>
      )
    }

    if (this.state.requiredFields.length > 0) {
      var requiredFieldsPopup = (
        <Modal show={this.state.requiredFields.length > 0} onHide={this.toggleRequiredFieldsPopup}>
          <Modal.Header closeButton>
            <Modal.Title>Missing fields</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>You are missing required fields in your post. You need to include:</p>
            <ul>
              {this.state.requiredFields.map((field, index) => 
                <li key={index}>{field}</li>
              )}
            </ul>            
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.toggleRequiredFieldsPopup}>Close</Button>
          </Modal.Footer>
        </Modal>
      )
    }

    if (this.state.optionalFields.length > 0) {
      var optionalFieldsPopup = (
        <Modal show={this.state.optionalFields.length > 0} onHide={this.toggleOptionalFieldsPopup}>
          <Modal.Header closeButton>
            <Modal.Title>Missing fields</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>You have not included the following in your post:</p>
            <ul>
              {this.state.optionalFields.map((field, index) => 
                <li key={index}>{field}</li>
              )}
            </ul>            
            <p>If this was an accident, please cancel and fill out the fields. Otherwise, click publish to continue</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.toggleOptionalFieldsPopup}>Cancel</Button>
            <Button onClick={this.submitPost}>Submit</Button>
          </Modal.Footer>
        </Modal>
      )
    }

    var uploadedImagesOverlay = (
      <Popover className='uploaded-images-popup-container'>
        <div id='uploaded-images-popup-container'  onBlur={() => {
        console.log('blurred')
      }}>
        {/*
        <div key='1234' onClick={this.insertImageIntoBody} className='uploaded-images-popup-image-container'
        title='Add image link to post'>
          <img src='https://deegram.azurewebsites.net/api/images/yfEk3OQpEd'/>
        </div>
        */}
        {this.state.uploadedImageIds.length > 0 ?
        (this.state.uploadedImageIds.map((id) => 
          <div key={id} onClick={this.insertImageIntoBody} className='uploaded-images-popup-image-container'
          title='Add image link to post'>
            <img src={getDeegramImage(id)}/>
          </div>)) : (<div>No images uploaded</div>)}
          </div>
      </Popover>
    )

    
    return (
      <React.Fragment>
        <Prompt when={!this.state.uploadedPostUrl} message='Are you sure you want to leave? Your drafted post will not be saved'/> 
      <div className='blogform triple-column padding-top' onKeyDown={this.onKeyDown}>
        <form  className='blogform-center-column'>
          <div className='blogform-header'>
            <img className='blogform-header-avatar' src={getSteemAvatar(this.state.userName)} />
            <h3 className='blogform-header-author'>{this.state.userName}</h3>
          </div>
          <br/>
          <FormGroup>
            <ControlLabel><p>Write a title</p></ControlLabel>
            <FormControl
              type="text"
              placeholder="Title..."
              onChange={this.handleChangeTitle}/>
          </FormGroup>
          <br />
          <ControlLabel><p>Write your post</p></ControlLabel>
          <HelpBoxes text='This editor uses Markdown to style your post. Please refrain from using special characters like *'/>
          <OverlayTrigger
          placement='bottom'
          overlay={uploadedImagesOverlay}
          trigger='click'
          >
            <Button className='blogform-trigger-uploaded-popup'>Uploaded images</Button>
          </OverlayTrigger>
          {/*react-wysiwyg editor editor tool made with draftjs*/}
          <Editor
            editorState={editorState}
            wrapperClassName='post-body-wrapper'
            editorClassName='post-body-editor'
            onEditorStateChange={this.onEditorStateChange}
            toolbar={{options: ['inline', 'blockType',  'list', 'emoji', 'image', 'history'],
              inline: {
                inDropdown: false,
                options: ['bold', 'italic', 'underline']
              },
              image: {defaultSize: {height: 'auto', width: '500px'}}
            }}
            style={{minWidth: 0}}
          />
          <br />
          <ImageCropView forPost={true} returnImageId={this.setCroppedImageId} multipleImages={true}/>
          <FormGroup>
            <ControlLabel>
              Add tags
            </ControlLabel>
            <HelpBoxes
              title='Tag'
              text='You can add up to 4 tags. Seperate tags with space.'/>
            {tagsWarning}
            <FormControl
              type="text"
              placeholder="tag1 tag2 tag3 tag4"
              onChange={this.handleChangeTags}/>
          </FormGroup>
          {errorAlert}
          <div className='submit-form-container'>
            <Button
              className='submit-form'
              bsStyle="primary"
              onClick={this.submitClicked}>
                Post
            </Button>
          </div>
          <div className='postblog-upload-status'>
            {uploadSpinner}
            {postUploaded}
            {uploadFailed}
          </div>
          {postPreview}
          {requiredFieldsPopup}
          {optionalFieldsPopup}
        </form>
      </div>
      </React.Fragment>
      );
    }
  }
