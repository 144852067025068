/**
 * Converts given time to 'x seconds/minutes (etc) ago'
 * @param {Date} created format 'YYYY-MM-DDTHH:MM:SS' - ex: '2019-03-14T15:09:25'
 */
export const convertTime = (created) => {
    //timestamp is UTC - adding '.000Z' makes sure it is interpreted as UTC instead of local time
    if (!created) {
      return '1 s ago';     
    }
    var convertTime = new Date(created + '.000Z');
    var now = new Date(Date.now());
    var milliseconds = Math.abs(now - convertTime);

    
    var timeAgo = Math.floor(milliseconds / 1000) + ' s ago';
    var seconds = milliseconds / 1000;
    if (seconds / 60 > 1) {
      timeAgo = Math.floor(seconds / 60) + ' min ago';
      var minutes = seconds / 60;
    }
    if (minutes / 60 > 1) {
      timeAgo = Math.floor(minutes / 60) + ' h ago';
      var hours = minutes / 60;
    }
    if (hours / 24 > 1) {
      timeAgo = Math.floor(hours / 24) + ' d ago';
      var days = hours / 24;
    }
    if (days / 7 > 1) timeAgo = Math.floor(days / 7) + ' w ago';
    if (days / 30 > 1) timeAgo = Math.floor(days / 30) + ' mo ago';
    if (days / 365 > 1) timeAgo = Math.floor(days / 365) + ' y ago';


    /*
    //old method
    var timeAgo = milliseconds / 1000 > 1 ? Math.floor(milliseconds / 1000) + ' seconds ago' : '1 second ago';
    var seconds = milliseconds / 1000;
    if (seconds / 60 > 1) {
      timeAgo = seconds / 60 > 2 ? Math.floor(seconds / 60) + ' minutes ago' : ' 1 minute ago';
      var minutes = seconds / 60;
    }
    if (minutes / 60 > 1) {
      timeAgo = minutes / 60 > 2 ? Math.floor(minutes / 60) + '  hours ago' : ' 1 hour ago';
      var hours = minutes / 60;
    }
    if (hours / 24 > 1) {
      timeAgo = hours / 24 > 2 ? Math.floor(hours / 24) + ' days ago' : ' 1 day ago';
      var days = hours / 24;
    }
    if (days / 7 > 1) timeAgo = days / 7 > 2 ? Math.floor(days / 7) + ' weeks ago' : ' 1 week ago';
    if (days / 30 > 1) timeAgo = days / 30 > 2 ? Math.floor(days / 30) + ' months ago': ' 1 month ago';
    if (days / 365 > 1) timeAgo = days / 365 > 2 ? Math.floor(days / 365) + ' years ago': ' 1 year ago';
    */
    return timeAgo;
}