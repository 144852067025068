import React from 'react';
import h2m from 'h2m';
import ReactMarkdown from 'react-markdown';
import Markup from 'interweave';

export const imageLinkRegex = /https*:\/\/((deegram(-test)*\.azurewebsites\.net\/api\/images\/\w+)|(([\w\d\.\-_\/:%]+\.(jpg|png|gif))))(?![\)\]])(?=([\s<&$])|$)/g;


/**
 * Converts body of a post or comment - formats html and markdown as react components, and adds links to i.e. '@steem'
 * @param {*} text body to be converted
 * @param {*} convertHtml default to true. Set to false for comments, can crash if on true
 * @param {*} allowImages decides if images should be displayed in rendered body. Usually set to false in feed body 
 * to prevent duplicate images. Defaults to true
 */
export const convertBody = (text, renderMarkdown = true, allowImages = true) => {
    var tmpBody = text;    
    try {
    if (allowImages) {
      tmpBody = tmpBody.replace(imageLinkRegex, '![]($&) ');
    }
    if (tmpBody) {
      var htmlTagRegex = new RegExp(/<\/*html>/g);
      tmpBody = tmpBody.replace(htmlTagRegex, '');

      //Does a regex search on body and inserts links 
      var userReference = new RegExp(/@[\w.]+(?=[\s.!?:])/g);
      tmpBody = tmpBody.replace(userReference, '<a href=\'/$&\'>$&</a>');
      //tmpBody = tmpBody.replace(userReference, '[](/$&)')
      
      //var tagReference = new RegExp(/\#\w+(?=[\s\.\!\?\:])/g);
      //tmpBody = tmpBody.replace(tagReference, '<a href=\'/$&\'>$&</a>')
      
      //checks for markdown link patterns
      var someRegex = new RegExp(/!*\[.*\]\(.*\)/g)
      var markdownQuote =  new RegExp(/\s>/g)
      var regex2 = new RegExp(/[#*]+[/s]/g)
      var blockList = allowImages ? null : ['img']
      var disallowedTypes = allowImages ? null : ['image']
      //if not markdown, treat body as html.
      if (!markdownQuote.test(tmpBody) && (!(someRegex.test(tmpBody)) || !renderMarkdown)) {
        //console.log(tmpBody)
        try {
          var renderedBody = renderMarkup(tmpBody, blockList);
        }
        catch (err) {
          var renderedBody = renderReactMarkdown(tmpBody, disallowedTypes)
        }
      } 
      //else render as markdown - tags like <br> gets converted to markdown
      //react-markdown does not perform well with nested links, i.e. an image that contains a link
      //semi-normal in posts on steem, hence the split in render methods
      else {
        //console.log(tmpBody)
        var renderedBody = renderReactMarkdown(tmpBody, disallowedTypes);
      }
      
      //tmpBody = tmpBody.replace(new RegExp(/(#)\w+/g), '<a href=\'/tag/$&\'>$& </a>');
      return renderedBody; 
    }
    }
    catch {
      //just return plaintext if nothing else works
      return tmpBody;
    }
    return null;
}

const renderMarkup = (text, blockList = null) => {
  return <Markup content={text} blockList={blockList}/>  
}

const renderReactMarkdown = (text, disallowedTypes = null) => {
  try {
    var renderedBody = <ReactMarkdown source={h2m(text)} disallowedTypes={disallowedTypes} />;
  }
  catch (err) {
    renderedBody = <ReactMarkdown source={text} disallowedTypes={disallowedTypes}/>;
  }
  return renderedBody;
}