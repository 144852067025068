import React, { PureComponent } from "react";
import {Button} from 'react-bootstrap';
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { uploadImage } from "./uploadImage";
import './imageCropView.css';
import HelpBoxes from "./helpBoxes";
import { deegramImageApi } from "./constants";
import { ImageCropper } from "./imageCropper";
import { BarLoader } from "react-spinners";

export class ImageCropView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            sources: [],
            crops: [],
            croppedImageFiles: [],
            cropping: false,
            showImagePreview: false,
            useEntireImage: false,
            imageIds: [],
        };
        this.initialCrop = {
          unit: "%",
          y: 20,
          x: 20,
          height: 60,
          width: 60,
        };
    }

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      this.setState({alreadySelected: false})
      let files = e.target.files;      
      for (let i = 0; i < files.length; i++) {  
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          let sources = [...this.state.sources];
          if (sources.indexOf(reader.result) === -1) {
            this.setState({ sources: [...this.state.sources, reader.result]})
          }
          else {
            this.setState({alreadySelected: true})
          }
        }); 
        reader.readAsDataURL(files[i]);
      }
    }
  };

  onImageCropped = (blob, index = 0) => {
    let croppedImageFiles = [...this.state.croppedImageFiles];
    croppedImageFiles[index] = blob;
    this.setState({croppedImageFiles: croppedImageFiles})
  }

  onImageRemoved = (index) => {
    let sources = [...this.state.sources];
    let files = [...this.state.croppedImageFiles];
    sources.splice(index, 1);
    files.splice(index, 1)
    this.setState({sources: sources, croppedImageFiles: files})
  }

  handleImageUpload = (e) => {
    e.preventDefault();
    this.setState({uploading: true})
    //if (this.props.multipleImages) {
      let files = this.state.croppedImageFiles
      for (let file of files) {
        uploadImage([file])
        .then((imageId) => {
          console.log(imageId);
          if (imageId) {
            this.setState({
              uploading: false,
              uploadSuccess: true,
              sources: [],
              showImagePreview: true,
              cropping: false,
              imageIds: [...this.state.imageIds, imageId],
              croppedImageFiles: [],
            })
            this.props.returnImageId(imageId);
          }
        })
        .catch(err => {
          console.log(err);
          this.setState({uploading: false})
        })
      }
      /*
      uploadImage(files)
      .then((imageId) => {
        console.log(imageId)
        if (imageId) {
          this.setState({
            uploadSuccess: true, 
            sources: [], 
            showImagePreview: true, 
            cropping: false, 
            imageIds: [...this.state.imageIds, imageId],
            croppedImageFiles: []
          })
          this.props.returnImageId(imageId);
        }
      })
      .catch(err => {
        console.log(err)
        this.setState({uploadFailed: true, cropping: false})
      })
      */
    /*}
    else {
      
      let file = [this.state.croppedImageFiles[0]]
      uploadImage(file)
      .then((imageId) => {
        if (imageId) {
          this.setState({
            uploadSuccess: true,
            sources: [],
            showImagePreview: true,
            cropping: false,
            imageIds: [...this.state.imageIds, imageId],
            croppedImageFiles: [],
          })
          this.props.returnImageId(imageId);
        }
      })
    }*/
  } 

  /**
   * Removes the image selected in file upload
   */
  clearImage = () => {
    var input = document.getElementById('image-input');
    if (input) {
      input.value = null;
      this.setState({sources: []})
    }
  }

  render() {
    const {sources } = this.state;

    if (sources.length > 0) {
      var clearImage = (
        <div class='image-crop-clear'>
          <Button onClick={this.clearImage}>Cancel</Button>
        </div>
      )
      var useImage = (
        <div className='image-crop-use-image'>
        <Button onClick={this.handleImageUpload}>{this.props.multipleImages ? 'Confirm all images' : 'Confirm'}</Button>
        </div>
      )
    }
    if (this.state.uploadSuccess) {
      var toggleImagePreview = (
        <div className='image-crop-toggle-preview'>
          <Button onClick={() => this.setState({showImagePreview: !this.state.showImagePreview})}>Toggle image preview</Button>
        </div>
      )
    } 
    if (this.state.showImagePreview) {
      var imagePreview = (
        <div className='image-crop-uploaded-images'>
          {this.props.forPost && (
          <>
            <h4>Images uploaded! To include images in your post, click the images in the "Uploaded images" box just above the post,
              or paste the image URL anywhere in the post body.
            </h4>
            <h4>A preview of the post is visible at the bottom of the page.</h4>
          </>
          )}
        {this.state.imageIds.map((id, index) =>
          <div key={index} className='image-crop-uploaded-image'>
            <img src={`${deegramImageApi}/${id}`} />            
            <p>Image URL: {`${deegramImageApi}/${id}`}</p>
          </div>
        )}
        </div>
      )
    }

    if (this.state.uploading) {
      var uploadingSpinner = (
        <div className='image-crop-use-image'>
        <BarLoader color={'#0085cd'} loading={true} height={5} width={150}/>
        </div>
      )
    }

    if (this.state.uploadFailed && !this.state.cropping) {
      var uploadFailed = (
        <div class='image-crop-upload-status'>
          Upload failed. Please try again later.
        </div>
      )
    }
    return (
      <div className={`image-crop-container ${this.props.className ? this.props.className : ''}`}>
        {sources.length > 3 ? 
          <div>You can only upload 50 images per post</div>
         : 
          <div className='image-crop-file-input-container'>
            <input className='btn btn-default image-crop-file-input' type="file" multiple={this.props.multipleImages} onChange={this.onSelectFile} 
            id='image-input' accept='.jpg, .png' title='Upload a .png or .jpg image'/>
            <HelpBoxes title='Image upload' text='You can upload an image of type png or jpg' placement='bottom'/>
            {this.state.alreadySelected && <p>One or more selected pictures are already selected and can not be uploaded again.</p>}
          </div>
        }
          <div className='image-crop-images'>
            {sources && (sources.map((src, index) =>
              <ImageCropper 
                key={src}
                index={index}
                src={src}
                onImageCropped={this.onImageCropped}
                onImageRemoved={this.onImageRemoved}
              />
              ))}
          </div>
        {clearImage} {this.state.uploading ? uploadingSpinner : useImage}
        {toggleImagePreview}
        {imagePreview}
        {uploadFailed}
      </div>
    );
  }
}
