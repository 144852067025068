import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

/**
 * Used in router to scroll to the top of the page after clicking a Link component
 * @param {*} param0 
 */
const ScrollToTop = ({children, location: {pathname}}) => {
  useEffect(() => {
    window.scrollTo(0,0);
  }, [pathname]);

  return children || null;
}

export default withRouter(ScrollToTop);