import React from 'react';
import {Form, FormControl, Button, Modal} from 'react-bootstrap';
import { getAccounts } from './apiCalls/getAccounts';
import './editProfilePage.css';
import { ImageCropView } from './imageCropView';
import { steemConnectEditProfile, getUrlProfile, getDeegramImage } from './constants';
import { runInThisContext } from 'vm';

export class EditProfilePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: localStorage.getItem('userName'),
            userProfile: null,
            existingData: null,
            profile_image: null,
            cover_image: null,
            name: null,
            about: null,
            location: null,
            website: null,
            showConfirmScreen: false,
            paramString: '',
            profile_image_file: null,
            cover_image_file: null,
            validProfileImage: true,
            validCoverImage: true,
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        let e = event.target;
        let initalParamString = '?';
        let paramString = initalParamString;
        //TODO: change purposfully empty strings to '%20' to send empty strings - use for 'deleting' a value
        //adds to the param string only if the param has a value
        paramString += e.profile_image.value && this.state.validProfileImage && e.profile_image.value !== this.current_profile_image  ? '&profile_image=' + e.profile_image.value : '';
        paramString += e.cover_image.value && this.state.validCoverImage && e.cover_image.value !== this.current_cover_image ? '&cover_image=' + e.cover_image.value : '';
        paramString += e.name.value  && e.name.value !== this.current_name ? '&name=' + e.name.value: '';
        paramString += e.about.value && e.about.value !== this.current_about ? '&about=' + e.about.value : '';
        paramString += e.location.value && e.location.value !== this.current_location ? '&location=' + e.location.value : '';
        paramString += e.website.value && e.website.value !== this.current_website ? '&website=' + e.website.value : '';

        if (paramString === initalParamString) {
          this.setState({showNoChangesScreen: true})
        }
        else {
          this.setState({paramString: paramString, showConfirmScreen: true});
        }
    }

    closeConfirm = () => {
        this.setState({showConfirmScreen: false})
    }

    handleConfirm = () => {
        const updateUrl = steemConnectEditProfile;
        document.location.href = updateUrl + this.state.paramString;
    }

    closeNoChanges = () => {
      this.setState({showNoChangesScreen: false})
    }

    onProfileImageChanged = (e) => {
      try {
        e.preventDefault();
        if (e.target && e.target.value) {
          this.setState({profile_image: e.target.value})
        }
      }
      catch (ex) {

      }
    }

    onCoverImageChanged = (e) => {
      try {
        e.preventDefault();
        if (e.target && e.target.value) {
          this.setState({cover_image: e.target.value})
        }
      }
      catch (ex) {

      }
    }

    setProfileImageFromImageId = (imageId) => {
        //imageId is false if image didnt upload
        if (imageId) {
          let url = getDeegramImage(imageId)
          this.setState({profile_image: url});
        }
    }

    setCoverImageFromImageId = (imageId) => {
        //imageId is false if image didnt upload
        if (imageId) {
          let url = getDeegramImage(imageId);
          this.setState({cover_image: url})
        }
    }

    isImageValid = (e) => {
      try {
        e.preventDefault();
        var isValid = e.type === 'load' ? true : false;
        switch(e.target.name) {
          case 'profile_image':
            this.setState({validProfileImage: isValid})
            break;

          case 'cover_image':
            this.setState({validCoverImage: isValid})
            break;
        }
      }
      catch (ex) {

      }
    }

    componentDidMount() {
        getAccounts(
            [this.state.userName],
            (err, result) => {
                if (result && result.length > 0) {
                    var r = JSON.parse(result[0].json_metadata).profile;
                    this.current_profile_image = r.profile_image;
                    this.current_cover_image = r.cover_image;
                    this.current_name = r.name;
                    this.current_about = r.about;
                    this.current_location = r.location;
                    this.current_website = r.website;
                    this.setState({
                        profile_image: r.profile_image ? `${r.profile_image}` : '',
                        cover_image: r.cover_image ? `${r.cover_image}` : '',
                        name: r.name ? r.name : '',
                        about: r.about ? r.about : '',
                        location: r.location ? r.location : '',
                        website: r.website ? r.website : '',
                    })
                }
            }
        )
    }

    render() {
        var confirmScreen = (
            <Modal show={this.state.showConfirmScreen} onHide={this.closeConfirm}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm changes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you ready to save your changes? Pressing confirm will take you to a SteemConnect interface to confirm your changes.
                <br />
                    You will have another chance to review your changes.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={this.closeConfirm}>Cancel</Button>
                    <Button variant='primary' onClick={this.handleConfirm}>Confirm</Button>
                </Modal.Footer>
            </Modal>
        )
        var noChangesMadeScreen = (
          <Modal show={this.state.showNoChangesScreen} onHide={this.closeNoChanges}>
            <Modal.Header closeButton>
              <Modal.Title>No changes were made to your profile</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              To edit your profile, at least one of the fields on this page will have to be changed.
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.closeNoChanges}>Close</Button>
            </Modal.Footer>
          </Modal>
        )
        var invalidImageText = (
          <div>
            Oops! It appears the provided link does not contain a valid image! Please double check that the link contains a valid image file.
          </div>
        )
        return (
            <div className='edit-profile-page'>
              <Button href={getUrlProfile(this.state.userName)}>Back to profile</Button>
              <h1>Steem username: {this.state.userName} </h1>
              <Form onSubmit={this.handleSubmit} id='updateForm'>
              <div>
                  <h4>Profile picture</h4>
                  <img name='profile_image' className='profile-image-preview' src={this.state.profile_image} 
                  onError={this.isImageValid} onLoad={this.isImageValid}/>
                  {!this.state.validProfileImage && invalidImageText}
                  <FormControl name='profile_image' type='text'  defaultValue={this.state.profile_image} onChange={this.onProfileImageChanged} />
                  <ImageCropView className='edit-profile-image-crop' returnImageId={this.setProfileImageFromImageId} multipleImages={false}/>
              </div>
              <div>
                  <h4>Cover image</h4>
                  {this.state.cover_image && <img name='cover_image' className='cover-image-preview' src={this.state.cover_image} 
                  onError={this.isImageValid} onLoad={this.isImageValid}/>}
                  {!this.state.validCoverImage && invalidImageText}
                  <FormControl name='cover_image' plaintext placeholder='image url...' defaultValue={this.state.cover_image} onChange={this.onCoverImageChanged}/>
                  <ImageCropView className='edit-profile-image-crop' returnImageId={this.setCoverImageFromImageId} />
              </div>
              <div>
                  <h4>Display name</h4>
                  <FormControl name='name' type='text' placeholder='Type a name...' defaultValue={this.state.name}/>
              </div>
              <div>
                  <h4>About</h4>
                  <FormControl name='about' form='updateForm' placeholder='Type something...' defaultValue={this.state.about}/>
              </div>
              <div>
                  <h4>Location</h4>
                  <FormControl name='location' type='text' placeholder='Type a place...' defaultValue={this.state.location}/>
              </div>
              <div>
                  <h4>Website</h4>
                  <FormControl name='website' type='text' placeholder='Type a website url...' defaultValue={this.state.website}/>
              </div>
              <Button type='submit'>Update</Button>
              <p>Note: a popup from steemconnect will ask you to confirm</p>
              </Form>
              {confirmScreen}
              {noChangesMadeScreen}
            </div>
        )
    }
}