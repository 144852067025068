import React from 'react'
import {Link} from 'react-router-dom';
import {getTrending} from './apiCalls/getTrending'
import {getUrlTag, getUrlPost, getSteemAvatar} from './constants';

//Shows top 5 trending deegram posts to the right at all times
export class TopTrendingList extends React.Component {
  constructor(props){
    super(props)

    this.state = { 
      topVotedList: []
    }
  }

  setList = (res) => {
    this.setState({topVotedList: res})
  }

  componentDidMount(){
    var query = {
      limit: 5,
      tag: 'deegram'
    }
    getTrending(query)
    .then(this.setList)
    .catch(err => {
      console.log(err);
    })
  }

  render(){
    return(
      <div className='top-trending-container '>
        <h3 style={{
          paddingLeft: 5,
          textAlign: 'center',
          color: '#000000',
        }}>Top trending for <Link to={getUrlTag('deegram')}>deegram</Link>
        </h3>
        <ol className='top-trending-list'>
          {this.state.topVotedList.map((post, idx) =>
            <li key={idx} className='top-trending-list-item'>
              <Link
                to = {getUrlPost(post.author, post.permlink)}
                style={{color: 'black'}}>
                <div className='top-trending-post-container'>        
                  <img src={getSteemAvatar(post.author)} className='top-trending-profile-picture' />      
                  <div className='top-trending-author'>{post.author}</div>
                  <div className='top-trending-post-title'>{post.title}</div>
                </div>
              </Link>
            </li>
          )}
        </ol>
      </div>
    )
  }
}
