import steem from 'steem';

/**
 * Returns information about steem accounts.
 * @param {string[] | string} accounts an array of 1 or more account names, or 1 account as a string
 */
export const getAccounts = (accounts) => {
  if (typeof accounts === 'string') {
    accounts = [accounts];
  }
  return new Promise((resolve, reject) => {    
    steem.api.getAccounts(accounts, (err, res) => {
      if (err) {
        reject(err);
      }
      if (res) {
        resolve(res)
      }
    })
  })
}
