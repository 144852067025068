import React from 'react';
import { PostBlogFormView } from './postBlogFormView';
import './postBlogForm.css';
import {api} from './apiCalls/steemConnectApi';

export class PostBlog extends React.Component {

  render() {

    var errorNotLoggedIn = (
      <div className='padding-top' >
        <p>
          You must log in before you can create a post. Click <a href={api.getLoginURL()} >here</a>
        </p>
      </div>)

    if(JSON.parse(localStorage.getItem('loggedIn'))){
        return(
          <PostBlogFormView />
        )
      } else {
        return (
          <div>{errorNotLoggedIn}</div>)
      }
  }
}
