import React from 'react';
import {Link} from 'react-router-dom';
import {Button} from 'react-bootstrap';
import { getFollowing } from './apiCalls/getFollowing';
import { getSteemAvatar, getUrlProfile, urlDiscover } from './constants';

/**
 * 'Friends' is in this context whoever the user is following
 */
const getFriends = (amount) => {
  return new Promise((resolve, reject) => {
    let userName = localStorage.getItem('userName');
    if (userName) {
      getFollowing(userName)
      .then(res => {
        var friends = [];
        for (var i = 0; i < res.length; i++) {
          friends.push(res[i].following);
        }
        resolve(getFriendsFollowing(friends, amount));
      })
      .catch(err => {
        console.log(err)
      })
    }
  })
}

const getFriendsFollowing = (friends, amount) => {
  return new Promise((resolve, reject) => {
    //accounts are put into this object as they are found
    //each property's name is an accountname, value is amount of times they appeared
    var followingMap = {};
    var friendsCopy = friends.slice(0);
    var counter = 0;
    var userName = localStorage.getItem('userName');
    for (var i = 0; i < 10 && i < friends.length; i++) {
      //removes a random account from the friends array
      var randomFollowing = friendsCopy.splice(Math.floor(Math.random() * friendsCopy.length),1)[0];
      getFollowing(randomFollowing)
      .then(res => {
        //must count up in callback function; otherwise counter will count before api calls are complete
        counter++;
        if (res && res.length > 0 && !res.error) {
          for (var j = 0; j < res.length; j++) {
            var friendFollowing = res[j].following;
            var randomFriend = res[j].follower;
            //adds following following to map if they are not user or already followed
            if (friendFollowing !== userName && friends.indexOf(friendFollowing) < 0) {
              let amountOfFollows = 1;
              var followedBy = [];
              //returns undefined (=falsy) if person is not already in map
              if (followingMap[friendFollowing]) {
                amountOfFollows = followingMap[friendFollowing].weight +1;
                followedBy = followingMap[friendFollowing].followedBy.slice();
              }
              followedBy.push(randomFriend);
              followingMap[friendFollowing] = {weight: amountOfFollows, followedBy: followedBy};
            }
          }
        }
        //console.log(followingMap);
        //sorting happens after all following data has been placed in followingMap
        if (counter === 10 || counter === friends.length) {
            resolve(setUpRecommendedList(followingMap, amount));
        }
      })
      .catch(err => {
        console.log(err);
      })
    }
  })
}

/**
 * Sorts data structure and updates state with final result, causing component to re-render
 * @param {*} followingMap data structure containing KVP for accounts in network
 */
const setUpRecommendedList = (followingMap, amount) => {  
  return new Promise((resolve, reject) => {
    var people = [];
    //moves data to new data structure to allow for sorting based on weight
    for (var person in followingMap) {
        people.push([person, followingMap[person].weight, followingMap[person].followedBy]);
    }
    //sorts the people array based on 2nd value in array (the weight), descending order
    people.sort( (a, b) => {
        return b[1] - a[1];
    })
    //amount accounts with highest weight
    var topMatches = people.slice(0, amount);
    //put back in a data structure easier to work with for placement on web page
    for (var i = 0; i < topMatches.length; i++) {
        topMatches[i] = {account: topMatches[i][0], weight: topMatches[i][1], followedBy: topMatches[i][2]}
    }
    //console.log(topMatches)
    sessionStorage.setItem('discoverList', JSON.stringify(topMatches));
    resolve(topMatches);
  })
}

export class DiscoverView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: localStorage.getItem('userName'),
            amountToShow: 6,
            /*
            people: [
                {account: 'deegramofficial', weight: 99, followedBy:['robinron', 'sflovik']},
                {account: 'robinron', weight: 88, followedBy:['sflovik', 'deegramofficial']},
                {account: 'sflovik', weight: 77, followedBy:['deegramofficial', 'robinron']},
            ],*/
        }
        this.updateList = this.updateList.bind(this);
    }

    updateList() {  
      getFriends(100).then(people => {
        this.setState({people: people.slice(0, this.state.amountToShow)})
      })   
    }

    componentDidMount() {
        if (sessionStorage.getItem('discoverList')) {
            this.setState({people: JSON.parse(sessionStorage.getItem('discoverList')).slice(0, this.state.amountToShow)})
        }
        else {
            this.updateList();
        }
    }

    render() {
        if (!this.state.userName || !this.state.people) {
            return null;
        }
        return (
            <div className={this.props.className}>
                <h3>Some accounts you might like
                    <span><Button className='discover-refresh-button' onClick={this.updateList}>Refresh</Button></span>
                </h3>
                    <Link className='discover-full-list' to={urlDiscover}>Full list</Link>
                {this.state.people.map((person, index) => 
                    <DiscoverPerson key={index} className='discover-person-container' person={person} 
                    followedBy={person.followedBy}/>                    
                )}
            </div>
        )
    }
}

class DiscoverPerson extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showFollowedBy: false,
        }
        //this.showFollowedBy = this.showFollowedBy.bind(this);
    }

    /*
    showFollowedBy(e) {
        this.setState({showFollowedBy: !this.state.showFollowedBy})
        e.target.innerHTML = e.target.innerHTML === '+' ? '-' : '+';
        e.preventDefault();
    }
    */

    render() {
        var person = this.props.person;
        if (!person) return null;
        //var randomFollower = person.followedBy[Math.floor(Math.random()*person.followedBy.length)];
        return (
          <Link to={getUrlProfile(person.account)}>
            <div className='discover-person-container'>
                    <img src={getSteemAvatar(person.account)} className='discover-profile-avatar'/>
                    <p>{person.account}</p>
                    <br />
                <div className='discover-person-followed-by'>
                    Followed by {this.props.person.followedBy[0]}
                    {person.followedBy.length > 1 && (
                        <span> and {person.followedBy.length} others </span>
                    )}
                </div>
                {/*
                    <Button className='discover-person-show-connections' 
                    onClick={this.showFollowedBy} title='Show connections'>+</Button>
                    {this.state.showFollowedBy && (
                        <div>
                            Followed by {person.followedBy[0]}
                            {person.followedBy.length > 1 && (
                                <span> and {person.followedBy.length} others </span>
                            )}
                        </div>
                    )}
                            */}
                    {/*this.state.showFollowedBy && (
                        <div>
                            <div>Connections: </div>
                            {person.followedBy.map((follower, index) => 
                                <Link key={index} to={`/profile/u/${follower}`}>{follower}<br/></Link>
                            )}
                        </div>
                            )*/}
            </div>
                </Link>
            )
    }
}

export class DiscoverPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        userName: localStorage.getItem('userName'),
        amountPerLoad: 20,
        amountToShow: 20, 
        currentList: [],
        fullList: [],
    }
    this.updateList = this.updateList.bind(this);
    this.expandList = this.expandList.bind(this);
}

updateList() {  
  getFriends(100).then(people => {
    this.setState({currentList: people.slice(0, this.state.amountToShow), fullList: people})
  })   
}

expandList() {
  var toShow = this.state.amountToShow + this.state.amountPerLoad;
  if (toShow <= this.state.fullList.length) {
    this.setState({currentList: this.state.fullList.slice(0, toShow), amountToShow: toShow})
  }
  else {
    this.setState({currentList: this.state.fullList, amountToShow: this.state.fullList.length})
  }
}


componentDidMount() {
  if (sessionStorage.getItem('discoverList')) {
    let fullList = JSON.parse(sessionStorage.getItem('discoverList'))
    this.setState({
      currentList: fullList.slice(0, this.state.amountPerLoad),
      fullList: fullList
    })
  }
  else {
    this.updateList();
  }
}

render() {
    if (!this.state.userName || !this.state.currentList.length) {
        return null;
    }
    return (
      <div className='discover-page padding-top'>
        <div className='discover-page-container'>
          <h3>Discover new accounts
              <span><Button className='discover-refresh-button' onClick={this.updateList}>Refresh</Button></span>
          </h3>
          <h5>Some accounts you might like, based on your current followings</h5>
          <br/>
          {this.state.currentList.map((person, index) => 
              <DiscoverPerson key={index} className='discover-page-person-container' person={person} 
              followedBy={person.followedBy}/>                    
          )}
          {this.state.amountToShow < this.state.fullList.length ? 
            <Button className='discover-page-expand-button' onClick={this.expandList}>Expand</Button> : ''}
        </div>
      </div>
    )
}
}