import _ from 'lodash'

export const getTagsFromMetadata = (jsonMetaData) => {
  const metadata = _.attempt(JSON.parse, jsonMetaData);
  if (_.isError(metadata)) return null;
  //console.log('getFromMetadata ', metadata.tags)
  return  metadata.tags
}

export const getImageFromMetadata = (jsonMetaData) => {
  const metadata = _.attempt(JSON.parse, jsonMetaData);
  if (_.isError(metadata)) return null;
  console.log('getFromMetadata ', metadata.image)
  return  metadata.image
}
