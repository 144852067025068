import React from 'react';
import {Link} from 'react-router-dom';
import {Form, FormGroup, Button, FormControl} from 'react-bootstrap';
import { lookupAccounts } from './apiCalls/lookupAccounts';
import './searchResults.css';
import { getSteemAvatar, getUrlProfile } from './constants';

/**
 * Page where results from a user search show up
 */
export class SearchResults extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      query: this.props.match.params.account ? this.props.match.params.account.toLowerCase() : this.props.match.params.account,
      results: [],
      resultAmountIncrement: 10,
      maxResults: 0,
    };
  }

  componentDidMount() {
    if (this.state.query) {
      this.makeNewSearch();
    }
  }

  makeNewSearch = (e) => {
    //document.location.href = `/search/${this.state.query}`
    lookupAccounts(this.state.query, this.state.resultAmountIncrement)
    .then(res => {
      this.setState({results: res, maxResults: this.state.resultAmountIncrement})
    })
    .catch(err => {

    })
    if (e) e.preventDefault();
  }

  getMoreAccounts = () => {
    let newLimit = parseInt(this.state.maxResults) + parseInt(this.state.resultAmountIncrement);
    lookupAccounts(this.state.query, newLimit)
    .then(res => {
      this.setState({results: res, maxResults: newLimit})
    });
  }
  
  updateSearchQuery = (e) => {
    this.makeNewSearch(e);
    this.setState({query: e.target.value.toLowerCase()});
  } 

  showInfo() {
    console.log('show');
  }

  hideInfo() {
    console.log('hide');
  }

  render() {
    if (this.state.results) {
      var results = (
        <div className='search-results-container'>          
        {this.state.results.map((person, index) =>  
          <Link className='search-result' key={index} to={getUrlProfile(person)}
            onMouseEnter={this.showInfo} onMouseLeave={this.hideInfo}>
            <img className='search-result-avatar' src={getSteemAvatar(person)} />
            <p>{person}</p>
          </Link>
        )}
        <div>Showing {this.state.maxResults} results</div>
        <Button href='#search'>Top of the page</Button>
        <Button onClick={this.getMoreAccounts}>More results</Button>
        </div>
      )
    }
    else {
      var results = (
        <div>
          <p>No results! :(</p>
          <p>Please refresh the page and try again</p>
        </div>
      )
    }

    return(
      <div className='search-results-page padding-top' id='search'>
        <Form onSubmit={this.makeNewSearch}>
          <FormGroup className='new-search-container'>
            <FormControl placeholder='Search for an account...' onChange={this.updateSearchQuery} defaultValue={this.state.query}/>
            <Button type='submit'>Search</Button>
          </FormGroup>
        </Form>
        <h2>Search for <span>{this.state.query}</span></h2>
        {results}
      </div>
    )
  }
}
