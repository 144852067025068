import React from 'react';
import './HelpPage.css';

//Hjelpeside med diverse informasjon som bruker får dersom de velger "Hjelp" i dropdown-menyen i navbaren.
export class HelpPage extends React.Component {

  render() {
    return(
      <div className='triple-column padding-top'>
        <div className='HelpPage' >
          <div className= "HelpInfo">
            <p style={{fontSize: '18px', fontWeight:'bold'}}> Velkommen til Blogchains hjelpesider </p>
            <p> Her kommer det til å stå informasjon om siden. </p>
            <p> Diverse informasjon </p>
            <p> Hjelp </p>
            <p> Tilfeldige ord i tilfeldige rekkefølger </p>
          </div>
        </div>
      </div>
    )
  }
}
