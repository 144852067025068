/**
 * 
 * @param {{}} steemPost 
 */
export const getPayout = (steemPost) => {
    try {
    if (!steemPost || steemPost.length === 0) return '0.000 SBD';
    var veryEarlyDate = new Date(2000,1,1);
    var payoutDate = new Date(steemPost.last_payout)
    //payoutDate is 01/01/1970 if rewards have not been claimed
    if (payoutDate > veryEarlyDate) {
        return steemPost.total_payout_value ? steemPost.total_payout_value : '0.000 SBD';
    }
    else {
        return steemPost.pending_payout_value ? steemPost.pending_payout_value : '0.000 SBD';
    }
    }
    catch (ex) {
        return '0.000 SBD';
    }
}