import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import { getAccounts } from './apiCalls/getAccounts';
import { getSteemAvatar, steemRateApi, sbdRateApi } from './constants';
import {convertToFloat} from './helpers/convertToFloat';
import { getDynamicGlobalProperties } from './apiCalls/getDynamicGlobalProperties';
import './walletPage.css';

export class WalletPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stats: {},
            statsArray: [],
            votingPower: null,
            showDonatePopup: false,
            profile_image: getSteemAvatar(this.props.account)
        }
    }

    handleResponse = async (result) => {
        const steemRate = await fetch(steemRateApi, {
            method: 'GET'
        }).then(res => res.json())
        .then(res => convertToFloat(res.ticker.price))
        .catch(err => console.log(err));
        //console.log(steemRate)

        const sbdRate = await fetch(sbdRateApi, {
            method: 'GET'
        }).then(res => res.json())
        .then(res => convertToFloat(res.ticker.price))
        .catch(err => console.log(err));
        //console.log(sbdRate);

        var r = result[0];
        var convertedJson = JSON.parse(r.json_metadata);
        if (convertedJson && convertedJson.profile && convertedJson.profile.profile_image) {
          this.setState({profile_image: convertedJson.profile.profile_image})
        }
        this.setState({
            stats: r,
            name: r.name,
            balance: r.balance,
            sbd_balance: r.sbd_balance
        }, () => {
            getDynamicGlobalProperties()
            .then(res => {
              var steemVestsString = res.total_vesting_shares;
              var steemPowerString = res.total_vesting_fund_steem;
              var vests = convertToFloat(steemVestsString);
              var power = convertToFloat(steemPowerString);

              var powerRatio = vests / power;
              var votingPower = convertToFloat(this.state.stats.vesting_shares);
              votingPower = votingPower / powerRatio;
              votingPower = Math.round((votingPower + 0.000001) * 1000) / 1000;
              this.setState({votingPower: votingPower});

              var steemBalance = convertToFloat(this.state.balance);
              var sbdBalance = convertToFloat(this.state.sbd_balance);
              var steemValue = steemRate ? (steemBalance * steemRate) : (steemBalance * 0.25);
              var sbdValue = sbdRate ? (sbdBalance * sbdRate) : (sbdBalance * 0.95);
              var votingValue = steemRate ? (votingPower * steemRate) : (votingPower * 0.25);
              var accountBalance = steemValue + sbdValue + votingValue;
              accountBalance = Math.round((accountBalance + 0.000001) * 1000) / 1000;
              this.setState({accountBalance: accountBalance.toLocaleString()})                
            })
            .catch(err => {
              
            })
        })
    }
    componentDidMount() {
        getAccounts([this.props.account])
        .then(this.handleResponse)
        .catch(err => {
          console.log(err);
        })
    }

    handleDonate = () => {
        this.setState({showDonatePopup: !this.state.showDonatePopup})
    }

    handleTransfer = () => {
        this.setState({showTransferPopup: !this.state.showTransferPopup})
    }

    render() {
        if (this.props.account === localStorage.getItem('userName')) {
            var donateButton = (
                <Button title='Cooming soon!' onClick={this.handleDonate}>Make a donation</Button>
            )

            var transferButton = (
                <Button title='Coming soon!' onClick={this.handleTransfer}>Transfer funds</Button>
            )
        }

        
        return (
            <div className='wallet-page padding-top'>
                <div className='wallet-container'>
                    <h1 className='wallet-header'>Wallet overview</h1>
                    <div className='wallet-user-avatar-container'>
                    <img className='wallet-user-avatar-image' src={`https://steemitimages.com/1024x1024/${this.state.profile_image}`} />
                    </div>
                    <h1>{this.props.account}</h1>
                    <div className='wallet-information-container'>
                      <h3>Estimated account value: ~${this.state.accountBalance}</h3>
                      <p>Steem: {this.state.balance}</p>
                      <p>Steem power: {this.state.votingPower}</p>
                      <p>Steem dollars: {this.state.sbd_balance}</p>
                    </div>
                    <div className='wallet-pay-buttons'>
                      {donateButton}
                      {transferButton}
                    </div>
                </div>
                <Modal show={this.state.showDonatePopup} onHide={this.handleDonate}>
                    <Modal.Header>
                        <Modal.Title>Donate coming soon!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        For now, you can use the Deegram app to donate
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleDonate}>Ok</Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showTransferPopup} onHide={this.handleTransfer}>
                    <Modal.Header>
                        <Modal.Title>Transactions coming soon!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleTransfer}>Ok</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}