import React from 'react';
import {ReadPost} from './blogFeed/ReadPost'
import {CommentSectionView} from './commentSectionView';
import './readPostContainer.css';
import { getContentReplies } from './apiCalls/getContentReplies';
import {postBlogForm} from './apiCalls/postBlogForm';
import { getContent } from './apiCalls/getContent';
import {votePost} from './apiCalls/votePost';
import {hasVoted} from './helpers/hasVoted';
import {NoMatch} from './routers';
import {getTagsFromMetadata} from './parsers';
import { voteWeight } from './constants';
import { getPayout } from './helpers/getPayout';
import { thisExpression } from '@babel/types';

export class ReadPostContainer extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      userName: localStorage.getItem('userName'),
      post: this.props.location && this.props.location.state ? this.props.location.state.post : {},
      netVotes: this.props.location && this.props.location.state ? this.props.location.state.netVotes : 0,
      author: this.props.match.params.author,
      permlink: this.props.match.params.permlink,
      contentReplies: [],
      highlighted: '',
      error: null,
      loading: false,
      weight: voteWeight,
      notFound: false,
     }
  }

  scrollToComments = () => {
    try {
      var elementToScrollTo = '';
      if ((this.props.location && this.props.location.state && this.props.location.state.toComments)) {
        elementToScrollTo = 'comments';   
      }   
      else {
        var url = window.location.href;
        elementToScrollTo = url.split('#').pop();        
      }
      if (elementToScrollTo) {
        //500 ms delay to allow page to render the comment section before redirecting to it
        setTimeout( () => {
          var element = document.getElementById(elementToScrollTo);
          if (element) {
            element.scrollIntoView();
            window.scrollBy(0, -50);
            this.setState({highlighted: elementToScrollTo})
          }
        }, 500)
      }
    }
    catch (ex) {

    }
  }

  getReplies = () => {
    getContentReplies(
      this.props.match.params.author,
      this.props.match.params.permlink,
    )
    .then(res => {
      this.setState({contentReplies: res}, () => {
        this.scrollToComments();
      })
    })
    .catch(err => {

    })
  }

  handleSubmitComment = (commentBody, parentAuthor, parentPermlink) => {
    return new Promise((resolve, reject) => {
      postBlogForm(
        parentAuthor, 
        parentPermlink,
        this.state.userName,
        '', //permlink
        '', //title
        commentBody,
        {}, //jsonMetadata,
      )
      .then(res => {
        this.getReplies();
        resolve();
      })
      .catch(err => {
        this.setState({commentError: err.error_description})
        reject();
      })
    })
  }

  handleVoteSlider = (weight) => {
    //console.log(weight)
    this.setState({weight})
  }

  handleUpVotePost = (res) => {
    let post = this.state.post
    post.hasVoted = res.hasVoted;
    post.activeVotes = res.activeVotes;
    this.setState({post: post})
  }

  handleContent = (res) => {
    if(res && !res.author && !res.permlink) {
      this.setState({notFound: true})
    }
    else {
      let jsonTags = getTagsFromMetadata(res.json_metadata)
      this.setState({
        notFound: false,
        post: {
          author: res.author,
          permlink: res.permlink,
          root_author: res.root_author,
          root_permlink: res.root_permlink,
          title: res.title,
          created: res.created,
          body: res.body,
          hasVoted: hasVoted(res.active_votes),
          netVotes: res.active_votes.length,
          activeVotes: res.active_votes,
          commentCount: res.children,
          payout: getPayout(res),
          tags: jsonTags,        
        }
      }, () => {
        console.log(this.state.post.hasVoted)
      })
    }
  }

  handleErrorContent = (err) => {
    this.setState({error: err})
  }

  componentDidMount() {
    this.initializeComponent();
  }

  componentDidUpdate = (prevProps) => {
    try {
      if (this.props.match.params.permlink !== prevProps.match.params.permlink 
        || this.props.match.params.author !== prevProps.match.params.author) {
          this.initializeComponent();
        }
    }
    catch(ex) {
    }
  }

  initializeComponent = () => {      
    //if (!this.props.location || !this.props.location.state || !this.props.location.state.post) {
      getContent(this.props.match.params.author, this.props.match.params.permlink)
      .then(this.handleContent)
      .catch(this.handleErrorContent)
    //}
    this.getReplies();
  }

  render() {
    if (this.state.notFound) {
      return NoMatch();
    }
    if (this.state.contentReplies && this.state.contentReplies.length > 0) {
      var commentSection = (
        <div id='comments' className='comments' style={{paddingTop: '30px'}}>
        <CommentSectionView
            parentAuthor={this.state.author}
            parentPermlink={this.state.permlink}
            userName={this.state.userName}
            contentReplies={this.state.contentReplies}
            highlighted={this.state.highlighted}
            indentation={0}/>
        </div>
      )
    }
    if (!this.state.post) {
      return null;
    }
    return(
      <div className='triple-column padding-top'>
        <div className='read-post'>
          <ReadPost
            post={this.state.post}
            tags={this.state.tags}
            hasVoted={this.state.post.hasVoted}
            handleSubmitComment={this.handleSubmitComment}
            handleUpVotePost={this.handleUpVotePost}
            handleVoteSlider={this.handleVoteSlider}
            commentError={this.state.commentError}
            loading={this.state.loading}/>
        </div>
        {commentSection}
      </div>
      

    )
  }
}
