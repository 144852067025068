import React from 'react';
import {FormGroup, ControlLabel, FormControl, Button, Modal, Panel} from 'react-bootstrap'
import './postCommentStyle.css';
import { PulseLoader } from 'react-spinners';

//Denne klassen tar input for å skrive en kommentar
export class PostCommentView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userName: this.props.userName,
      commentBody: '',
      parentAuthor: this.props.parentAuthor,
      parentPermlink: this.props.parentPermlink,
      open: false,
      replyComplete: false,
      replySuccess: false,
      sendingReply: false,
      hidden: false,
    }
  }

  hidePopup = () => {
    this.setState({hidden: true, commentError: ''})
  }

  handleChangeCommentBody = (e) => {
    this.setState({commentBody: e.target.value})
  }

  submit = (e) => {
    e.preventDefault()
    this.setState({sendingReply: true})
    const commentText  = this.state.commentBody;
    if (commentText) {
      this.props.onSubmit(commentText, this.state.parentAuthor, this.state.parentPermlink)
      .then(res => {
        this.setState({
          open: false,
          replySuccess: true,
          commentBody: '',
          hidden: false
        })
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        this.setState({replyComplete: true, sendingReply: false})
      })
    }
    else {
      this.setState({
        replyComplete: true,
        sendingReply: false,
        hidden: false,
        commentError: 'You can\'t post an empty comment!'
      })
    }
  }

  render() {
    if (this.state.replyComplete) {
      var didReply = (
        <div>{this.state.replySuccess ? 'Replied!' : 'Unable to reply, please try again later.'}</div>
      )
    }

    if (this.state.sendingReply) {
      var replySpinner = (
        <div className='reply-loading-spinner'>
          <PulseLoader
          color={'#FF8D36'}
          loading={true} />
        </div>
      )
    }

    //  console.log('parentAuthor' + this.state.parentAuthor)
    return(

      <div className={this.props.className}>
        <form onSubmit={this.submit}>
          <Button
            onClick={() => this.setState({open: !this.state.open})}
          >Reply
          </Button>
          {this.state.sendingReply ? replySpinner: didReply}
          <Panel  className='readpost-write-reply-container' expanded={this.state.open}>
            <Panel.Collapse>
              <FormGroup >
                <ControlLabel>Write a reply</ControlLabel>
                <FormControl
                  className = "replyField"
                  componentClass="textarea"
                  placeholder="write a reply..."
                  onChange={this.handleChangeCommentBody}
                  style={{height: 150}}
                  value={this.state.commentBody}
                />
              </FormGroup>
              <Button
                className='Search-Button'
                type='submit'
              >Post reply</Button>
            </Panel.Collapse>
          </Panel>
        </form>
        <Modal show={this.state.replyComplete && !this.state.replySuccess && !this.state.hidden}
               onHide={this.hidePopup} dialogClassName='vote-failed-dialog' centered='true'>
            <Modal.Header>
                <Modal.Title>Could not comment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.props.commentError && (
                <div>
                  {this.props.commentError}
                  <br/>
                  Try again later.
                </div>
              )}
              {this.state.commentError}
            </Modal.Body>
            <Modal.Footer>
                <Button variant='primary' onClick={this.hidePopup}>Ok</Button>
            </Modal.Footer>
        </Modal>
      </div>
    )
  }
}
