import {api} from './steemConnectApi';
import Cookies from 'universal-cookie';

/**
 * Revokes the logged in user's accessToken and deletes the saved data in sessionStorage
 * and deletes the (now expired) accessToken cookie
 * @param {*} callback callback function.
 */
export const logoutUser = (callback) => {
  const cookies = new Cookies();
  api.revokeToken((err, res) => {
    console.log(err, res)
    if (res || (err && err.error === 'invalid_grant')) {
      localStorage.setItem('userName', '');
      localStorage.removeItem('userName');
      cookies.set('token', '');
      cookies.remove('token');
      localStorage.setItem('loggedIn', '');
      localStorage.removeItem('loggedIn');
      localStorage.removeItem('discoverList');
      localStorage.setItem('showSlider', '');
      localStorage.removeItem('showSlider');
      localStorage.removeItem('voteWeight')
      sessionStorage.setItem('loggedOut', true);
      console.log('Fjernet session');
      callback();
    }
});
}
