import React from 'react';
import {Button} from 'react-bootstrap';
import {CommentBox} from './commentBox';
import { getUrlPost } from './constants';


//En klasse som henter kommentarer til et innlegg og oppretter en CommentBox per
//kommentar som viser kommentaren. Oppretter en PostCommentView klasse for å kunne
//svar på innlegget kommentarfeltet tilhører.
export class CommentSectionView extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      userName: this.props.userName,
      parentAuthor: this.props.parentAuthor,
      parentPermlink: this.props.parentPermlink,
      highlighted: this.props.highlighted,
      indentation: this.props.indentation ? this.props.indentation : 0,
    }
  }

  componentDidMount() {
    this.setState({contentReplies: this.props.contentReplies});
  }

  //oppretter en commentbox for hver kommentar til innlegget
  render() {
    if(this.props.contentReplies) {
      return(
        <div>
          {this.props.contentReplies.map((reply, idx) =>
            <div key={idx}>
            <CommentBox
              onSubmit={this.handleSubmitComment}
              userName={this.state.userName}
              parentAuthor={this.state.parentAuthor}
              parentPermlink={this.state.parentPermlink}
              reply={reply}
              highlighted={this.state.highlighted}
              indentation={this.state.indentation}/>
            </div>
          )}
        </div>
      )
    }
    else {
      return(<div/>)
    }
  }
}
