import steem from 'steem';

/**
 * Get following and followers for a specific account
 * @param {string} account 
 */
export const getFollowing = (account) => {
  return new Promise((resolve, reject) => {
    steem.api.getFollowing(
      account, 
      '', 
      'blog', 
      1000,
      (err, res) => {
          if (res) {
            resolve(res);
          }
          else {
            reject(err);
          }
      })
  })
}