import steem from 'steem';

/**
 * Returns information about the blockchain, such as total vests, vesting rates
 */
export const getDynamicGlobalProperties = () => {
  return new Promise((resolve, reject) => {    
    steem.api.getDynamicGlobalProperties(
      (err, res) => {
        if (res) {
          resolve(res);
        }
        else {
          reject(err);
        }
      }
    )
  })
}