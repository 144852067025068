import React from 'react';
import {Button, FormControl} from 'react-bootstrap';
import {setVoteWeight, voteWeight, maxVoteWeight, minVoteWeight, voteSliderStep}  from './constants';

export class VoteSlider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: this.props.show,
            voteWeight: voteWeight,
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
      e.preventDefault();
      var voteWeight = e.target.value.replace(/[^0-9]*/g, '');
      var parsed = parseInt(voteWeight);
      if (parsed) { //|| parsed === 0) {
        if (parsed > maxVoteWeight) {
            parsed = maxVoteWeight;
        }
        else if (parsed < 0) { //minVoteWeight) {
            parsed = 0
        }
        voteWeight = parsed;
          
      }
      this.setState({voteWeight: voteWeight, saved: false}, () => {
        this.props.onChange(voteWeight)
      })
    }

    setVoteWeight() {
        localStorage.setItem('voteWeight', this.state.voteWeight);
        setVoteWeight(this.state.voteWeight);
    }

    render() {
        return (
          <div className='settings-vote-weight'>
            <label htmlFor='vote-slider-popup'>
                Set vote weight <br /> From 0% to 100%
            </label>
            <div className='settings-vote-weight-input-bar'>
                <Button className='settings-weight-min' onClick={() => this.setState({voteWeight: minVoteWeight})}>{minVoteWeight}</Button>
                <FormControl className='settings-vote-weight-input' type='text' value={this.state.voteWeight} onChange={this.handleChange}/>
                <Button className='settings-weight-max' onClick={() => this.setState({voteWeight: maxVoteWeight})}>{maxVoteWeight}</Button>
            </div>
            <FormControl step={voteSliderStep} type='range' id='vote-weight-slider' min={minVoteWeight} max={maxVoteWeight} value={this.state.voteWeight} 
            onChange={this.handleChange}/>
        </div>
        )
    }
}