import steem from 'steem';

/**
 * Returns the currently most popular tags used on the blockchain.
 * @param {string} afterTag returns tags less popular than this (inclusive). Leave blank to get most popular.
 * @param {string | number} limit amount of tags that are returned.
 * @returns {Promise<[{name:string}]>}
 */
export const getTrendingTags = (afterTag = '', limit) => {
  return new Promise((resolve, reject) => {
    steem.api.getTrendingTags (
      afterTag,
      limit,
      (err, res) => {
        if (res) {
          resolve(res);
        }
        else {
          reject(err);
        }
      }
    )
  })
}