import React from 'react'
import {NavLink} from 'react-router-dom';
import { getTrendingTags } from './apiCalls/getTrendingTags';
import { getUrlTag } from './constants';

//This class shows popular tags
export class TrendingTags extends React.Component {
  constructor(props){
    super(props)    
    this.state = { 
      trendingTags: [{name: 'steem'}, {name: 'dtube'}, {name: 'photography'}],
      currentTag: this.props.match ? this.props.match.params.tag : '',
      feedType: this.props.feedType
    }
  }

  componentDidMount() {
    getTrendingTags('', 15)
    .then(res => {
      this.setState({trendingTags: res});
    })
    .catch(err => {

    })
  }

  render(){
    return(
      <div className='trending-tags-container '>
        <h3 className='trending-tags-title'>Popular tags
        </h3>
        <ul className='trending-tags'>
          <div className='trending-tag-container'>
            {/* all tags and deegram hardcoded to always be on top */}
            <NavLink to='/all-tags/trending' activeClassName='current-tag'>
              <li className={'trending-tag'}>
                all tags
              </li>
            </NavLink>
            <NavLink to={getUrlTag('deegram')} activeClassName='current-tag'>
              <li className={'trending-tag'}>
                deegram
              </li>
            </NavLink>
            {this.state.trendingTags.map((tag, index) =>
            <NavLink key={index} to={getUrlTag(tag.name)} activeClassName='current-tag'>
              <li className={'trending-tag'}>
                {tag.name}
              </li>
            </NavLink>
            )}
          </div>
        </ul>
      </div>
    )
  }
}
