import {api} from './steemConnectApi';

/**
 * Votes on a post (or comment)
 * @param {string} voter name of user voting.
 * @param {string} author author of the post.
 * @param {string} permlink permlink of the post.
 * @param {string | number} weight how many % the user votes with, positive = upvote, negative = downvote
 */
 export const votePost = async (voter, author, permlink, weight) => {
   console.log(voter,author, permlink, weight)
  return new Promise((resolve, reject) => {
    api.vote(
    voter,
    author,
    permlink,
    parseInt(weight) * 100, //positive weight is upvote, negative is downvote / flag
    (err, res) =>  {
      if (res) {
        resolve(res);
      }
      else {
        reject(err);
      }
  });
  })
}
