import React from 'react';
import AppStoreIcon from './img/App_Store_iOS_11.png';
import './appLinksPage.css';
import { urlFeedbackForm } from './constants';

export class AppLinksPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }


  render() {

    return (
      <div className='app-links-page padding-top'>
        <div className='app-links-header'>
          <h4>Thanks for visiting our site! Below you can find links to our Android and iOS apps.</h4>
          <h4>If you experience any issues or have any concerns, ideas or input, please do not hesitate to fill out <a href={urlFeedbackForm}>our feedback form!</a></h4>
          <h4>We truly appreciate all honest feedback!</h4>
        </div>
        <div className='app-links-container'>
          <div className='android-app-link'>
          <h2>Android</h2>          
            <a className='' href={`https://play.app.goo.gl/?link=https://play.google.com/store/apps/details/?id=com.DecentralizeIT.DeeGram&ddl=1&pcampaignid=web_ddl_1`}>
              <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png'/>
            </a>
          </div>
          <div className='ios-app-link'>
          <h2>iOS (via TestFlight)</h2>
            <a className='' href='https://testflight.apple.com/join/LHy49keZ'>
              <img alt='Try it on TestFlight' src={AppStoreIcon}/>
            </a>
          </div>
        </div>
      </div>
    )
  }
}