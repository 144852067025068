import React from 'react';
import {Button} from 'react-bootstrap';
import {GetFeedContent} from './blogFeed/getFeedContent';
import './homePageStyle.css';
import {TopTrendingList} from './topTrendingList';
import {TrendingTags} from './trendingTags';
import { DiscoverView } from './discover';
import { urlNewPost } from './constants';
import scrollToTop from './scrollToTop';
export class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
    this.handleNoContent = this.handleNoContent.bind(this);
  }

  handleNoContent(callback) {
    var message = `No posts found`
    return callback(message);
  }

  componentDidUpdate = (prevProps) => {
    try {
      window.scrollTo(0,0)
    }
    catch(ex) {
    }
  }

  render(){
    var isMobile = window.matchMedia('only screen and (max-width: 767px').matches;
    if (!isMobile) {
      var discover = (
        <DiscoverView className='discover-container'/>
      )
      var trendingTags = (
        <TrendingTags match={this.props.match ? this.props.match : ''} feedType={this.props.feedType}/>
      )
      var topTrending = (
        <TopTrendingList match={this.props.match}/>
      )
    }

    var isIE = !!document.documentMode;
    var isEdge = !isIE && !!window.StyleMedia;
    return(    
      <div className='home-triple-column padding-top'>
        <div className='home-center-column'> 
          {isMobile && <div className='mobile-new-post-container'><Button href={urlNewPost} className='mobile-new-post-button'>+</Button></div>}
          <GetFeedContent match={this.props.match ? this.props.match : ''} feedType={this.props.feedType} 
          feedOptions={this.props.feedOptions} onNoContent={this.handleNoContent} callback={this.props.callback}/>
        </div>
        <div className={`home-fixed-container${isEdge ? ' padding-top' : ''}`}>
          {trendingTags}
          {discover}
          {topTrending}
        </div>
      </div>
    )
  }
}
