import React, { PureComponent } from "react";
import {Button} from 'react-bootstrap';
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { uploadImage } from "./uploadImage";
import './imageCropView.css';
import HelpBoxes from "./helpBoxes";
import { deegramImageApi } from "./constants";

export class ImageCropper extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            src: this.props.src,
            crop: null,
            croppedImageFile: null,
            cropping: false,
            showImagePreview: false,
            useEntireImage: false,
            imageId: null,
        };
        this.initialCrop = {
          unit: '%',
          y: 20,
          x: 20,
          height: 60,
          width: 60,
        };
    }

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop) => {
    this.setState({ crop });
    this.setState({cropping: true})
  };

  makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      )
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          return;
        }
        blob.name = fileName;
        this.setState({croppedImageFile: blob})
        this.props.onImageCropped(blob, this.props.index);
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl, blob);
      }, "image/jpeg");
    });
  }

  removeImage = () => {
    //this.setState({src: null, croppedImageFile: null}, () => {
      this.props.onImageRemoved(this.props.index);
    //});
  }

  resetCrop = () => {
    this.setState({crop: this.initialCrop})
  }

  useEntireImage = () => {
    var crop = {unit: '%', y: 0, x: 0, height: 100, width: 100};
    this.setState({useEntireImage: true, crop: crop}, () => {
      this.makeClientCrop(crop);
    });
  }

  componentDidMount() {
    this.setState({crop: this.initialCrop})
  }

  render() {
    const { crop, src } = this.state;

    if (this.state.src) {
      var useFullImage = (
        <div className='image-crop-full-image'>
        <Button onClick={this.useEntireImage}>Full size</Button>
        </div>
      )
      var resetCrop = (
        <div className='image-crop-reset-crop'>
        <Button onClick={this.resetCrop}>Reset crop</Button>
        </div>
      )
      var removeImage = (
        <div className='image-crop-remove-image'>
          <Button onClick={this.removeImage}>Remove</Button>
        </div>
      )
    }
    return (
      <div class={`image-crop-image-container`}>
        <div className='image-crop-image-controls' >{removeImage}{resetCrop}{useFullImage}</div>
        <ReactCrop
          src={src}
          crop={crop}
          onImageLoaded={this.onImageLoaded}
          onComplete={this.onCropComplete}
          onChange={this.onCropChange}
        />
      </div>
    );
  }
}
