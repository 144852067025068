import React from 'react';
import ProfileView from './profileView.js';
import {getAccounts} from './apiCalls/getAccounts';
import {NoMatch} from './routers';
import { getFollowCount } from './apiCalls/getFollowCount';
import {getFollowing} from './apiCalls/getFollowing';


export class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userProfile: [],
      userName: this.props.match.params.userName,
      followerCount: '',
      followingCount: '',
      isFollowing: null,
      notFound: false,
      ownProfile:  this.props.match.params.userName === localStorage.getItem('userName')
    };
  };

  setProfile = (err = null, res = null) => {
    if(err || res.length < 1){
      this.setState({notFound: true})
    } else if (res){
      this.setState({userProfile: res})
      getFollowCount(this.state.userName, this.setFollowCounts);
      this.checkFollowing();
    };
  };

  setFollowCounts = (err, result) => {
    if (result) {
      this.setState({followerCount: result.follower_count, followingCount: result.following_count});
    }
  }

  initializeProfile = () => {
    document.title = this.state.userName + '\'s profile';
    getAccounts([this.props.match.params.userName])
    .then(res => {
      this.setProfile(null, res)
    })
    .catch(err => {
      console.log(err, null)
    })
  }

  componentDidMount () {
    this.initializeProfile();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.userName !== prevProps.match.params.userName) {
      this.initializeProfile();
    }
  }

  checkFollowing = () => {
    getFollowing(localStorage.getItem('userName'), (err, result) => {
      //console.log(err, result);
      if (result && result.length > 0) {
        for (var following of result) {
          if (following.following === this.state.userName) {
            this.setState({isFollowing: true}, () => {/*console.log(this.state.isFollowing)*/})
            return true;
          }
        }
      }
    })
  }

  render() {
    if (this.state.notFound) {
      return NoMatch();
    }
   var newProfileInfo = {
   }
     return (
      <div className='padding-top profile-page'>
        {this.state.userProfile.map((user, index) => {
          newProfileInfo.userName = user.name;
          newProfileInfo.accountCreated = user.created.slice(0, 4);
          newProfileInfo.jsonMetadata = user.json_metadata;
          newProfileInfo.postCount = user.post_count;
          newProfileInfo.followerCount = this.state.followerCount;
          newProfileInfo.followingCount = this.state.followingCount;
          //newProfileInfo.isFollowing = this.state.isFollowing;
          newProfileInfo.ownProfile = this.state.ownProfile;
        })}
        <ProfileView match={this.props.match} userInfo={newProfileInfo} callback={this.props.callback}
         feedOptions={this.props.feedOptions} isFollowing={this.state.isFollowing}/>
      </div>
    )
  }
}
