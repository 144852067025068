import steemconnect from 'steemconnect';

//for testing locally
const localhostOptions = {
  app: 'blogchain.app',
  callbackURL: 'http://localhost:3000/',
  scope: ['login', 'vote', 'comment', 'custom_json', 'comment_options'], //app permissions - doesnt seem to do anything as of july 12 2019 - due to v2 -> v3?
}

//for build
const newOptions = {
  app: 'deegram', 
  callbackURL: 'https://www.deegram.com/complete/steemconnect/',
  scope: ['vote', 'comment', 'custom_json', 'comment_options'], //app permissions - doesnt seem to do anything as of july 12 2019 - due to v2 -> v3?
}

//old domain
const oldOptions = {
  app: 'deegram',
  callbackURL: 'https://deegram.no/',
  scope: ['vote', 'comment', 'custom_json', 'comment_options'],
}

var isLocalhost = window.location.href.includes('localhost:3000')
var isOldDomain = window.location.href.includes('deegram.no');
/**
 * A client to login etc with steemconnect
 */
export const api = new steemconnect.Client(isLocalhost ? localhostOptions : (isOldDomain ? oldOptions : newOptions));


