import steem from 'steem';

//henter ett spesifikt innlegg fra steem og returnerer innholdet
/**
 * Returns a post on the steem blockchain.
 * @param {string} author author of the post
 * @param {string} permlink permlink of the post (on steemit: last part of url)
 */
export const getContent = (author, permlink) => {
  return new Promise((resolve, reject) => {
    steem.api.getContent(
      author,
      permlink,
      (err, res) => {
        if (err) {
          reject(err);
        }
        else if (res) {
          resolve(res)
        }
        else {
          reject();
        }
      }
    )
  })
}
