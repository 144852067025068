import React from 'react';
import {Link} from 'react-router-dom';
import {Button, Popover} from 'react-bootstrap';
import {getAccountHistory} from './apiCalls/getAccountHistory';
import {convertTime} from './convertTime';
import { PulseLoader } from 'react-spinners';
import { getSteemAvatar, urlPost } from './constants';
import './notification.css';


export class NotificationPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          notificationsPerPage: 100,
          notifications: [],
        }
    }

    componentDidMount() {
      this.fetchNotifications();
    }

    changeNotificationTypes = (e) => {
      console.log(e.target.value)
      if (e && e.target) {
        this.setState({notificationType: e.target.value.toLowerCase()})
      }
    }
    
    //fetches notifications starting at the newest
  fetchNotifications = (from = this.state.lastNotification, amountFetched = 0) => {
    //exits recursion if there are no more notifications
    if (this.state.fetchedAll) return;

    var limit = this.state.notificationsPerPage;
    //sets the new fetch start from 1 before last notification fetched
    from = (from > 0) ? from - 1 : from;
    //if limit is greater than starting position, set limit to start position 
    limit = (limit > from && from !== -1) ? from : limit;
    getNotifications(from, limit)
    .then(res => {
      let notifications = res.notifications;
      let lastNotification = res.lastNotification;
      this.setState({
        notifications: this.state.notifications ? this.state.notifications.concat(notifications) : notifications,
        lastNotification: lastNotification,
      }, () => {
        //leaves recursion because all notifications have been fetched at this point
        if (lastNotification === 0) {
          this.setState({fetchedAll: true, loading: false})
          return;
        }
        amountFetched += notifications.length;
        //ensures a fetch gets at least 5 new visible notifications
        if (amountFetched < 5) {
          this.fetchNotifications(lastNotification, amountFetched);
        }
        else {
          this.setState({loading: false})
        }
      });
    })
    .catch(err => {
      console.log(err)
    })
  }

  clickFetch = () => {
    if (!this.state.loading) {
      this.setState({loading: true}, () => this.fetchNotifications());
    }
  }

  componentDidMount() {
    document.addEventListener('scroll', this.trackScrolling);
  }
  
  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrolling);
  }

  trackScrolling = () => {
    const bottom = document.getElementById('notifications-bottom-page');
    const hasReachedBottom = bottom.getBoundingClientRect().top <= window.innerHeight;
    if (!this.state.loading && hasReachedBottom) {
      this.setState({loading: true}, () => this.fetchNotifications());
    }
  }

    render() {
      if (this.state.loading && !this.state.fetchedAll) {
        var scrollToLoad = (
          <PulseLoader />
        )
      }
      else if (!this.state.fetchedAll) {
        var scrollToLoad = (
          <div className='notifications-scroll-message'><Button onClick={this.clickFetch}>
            Scroll down to load more...
          </Button></div>
        )
      }
        return (
            <div className='notification-page padding-top'>
              <div className='notification-page-header'>
                <h1>Notifications center</h1>
                <form onChange={this.changeNotificationTypes}>
                  <label htmlFor='select-notification-type'>View notifications: </label>
                  <select as='select' id='select-notification-type'>
                    <option value=''>All </option>
                    <option value='vote'>Votes</option>
                    <option value='comment'>Comments</option>
                    <option value='transfer'>Transfers</option>
                    {/*<option value='delegate_vesting_shares'>Delegations</option>*/}
                  </select>
                </form>
              </div>
              <div className='notifications-list-container'>
                {this.state.notifications.map((notification, index) =>
                <Link to={notification.href} className='notification-link'>
                    <div key={index} style={{margin: '20px auto'}} 
                    className={`notification-page-single-container ${(!this.state.notificationType || notification.type === this.state.notificationType) ? 'notification-show' : 'notification-hide'}`}>
                      <img className='notification-page-user-avatar' src={getSteemAvatar(notification.user)} />
                      <p className='notification-page-message'>{notification.user} {notification.message}</p>
                      <p className='notification-page-timestamp'>{convertTime(notification.timestamp)}</p>
                    </div>    
                </Link>
                )}
                <div className='notifications-bottom-container'>
                  <div id='notifications-bottom-page'>{scrollToLoad}</div>
                </div>
              </div>
            </div>
        )
    }
}

export class NotificationWindow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationsPerPage: 100,
      allNotifications: [],
      notifications: [],
      loading: true,
      fetchedAll: false,
      lastNotification: -1,
    }
  }

  //fetches notifications starting at the newest
  fetchNotifications = (from = this.state.lastNotification, amountFetched = 0) => {
    //exits recursion if there are no more notifications
    if (this.state.fetchedAll) return;

    var limit = this.state.notificationsPerPage;
    //sets the new fetch start from 1 before last notification fetched
    from = (from > 0) ? from - 1 : from;
    //if limit is greater than starting position, set limit to start position 
    limit = (limit > from && from !== -1) ? from : limit;
    getNotifications(from, limit)
    .then(res => {
      let notifications = res.notifications;
      let lastNotification = res.lastNotification;
        this.setState({
          notifications: this.state.notifications ? this.state.notifications.concat(notifications) : notifications,
          lastNotification: lastNotification,
        }, () => {
          //leaves recursion because all notifications have been fetched at this point
          if (lastNotification === 0) {
            this.setState({fetchedAll: true, loading: false})
            return;
          }
          amountFetched += notifications.length;
          //ensures a fetch gets at least 5 new visible notifications
          if (amountFetched < 5) {
            this.fetchNotifications(lastNotification, amountFetched);
          }
          else {
            this.setState({loading: false})
          }
        });
    })
    .catch(err => {
      console.log(err);
      this.setState({loading: false})
    })

  }

  handleKeyDown = (e) => {
    try {
      if (e.keyCode === 27) {
        this.props.onEscapePressed();
      }
    }
    catch (ex) {

    }
  }

  isBottom = (element) => {
    var container = document.getElementById('notifications-container').getBoundingClientRect();
    return element.getBoundingClientRect().top <= container.bottom;
  }

  componentDidMount() {
    this.setState({loading: true}, () => {
      this.fetchNotifications()
    })
    document.getElementById('notifications-container').addEventListener('scroll', this.trackScrolling);
  }

  componentWillUnmount() {
    document.getElementById('notifications-container').removeEventListener('scroll', this.trackScrolling);
  }

  trackScrolling = () => {
    const wrappedElement = document.getElementById('notifications-bottom-window');
    if (this.isBottom(wrappedElement) && !this.state.loading) {
      this.setState({loading: true}, this.fetchNotifications);
    }
  }

  clickFetch = () => {
    if (!this.state.loading) {
      this.setState({loading: true}, () => this.fetchNotifications());
    }
  }


  render() {
    if (!this.state.notifications) return null;

    if (this.state.loading && !this.state.fetchedAll) {
      var scrollToLoad = (
        <PulseLoader />
      )
    }
    else if (!this.state.fetchedAll) {
      var scrollToLoad = (
        <div className='notifications-scroll-message'><Button onClick={this.clickFetch}>
          Scroll down to load more...
        </Button></div>
      )
    }
    return (
      <div className='notifications-container' id='notifications-container' onKeyDown={this.handleKeyDown}>
        {this.state.notifications.map((notification, index) =>  
        <Link key={index} to={notification.href}>    
          <div className={`notification-single-container`}>
            <img className='notification-user-avatar' src={getSteemAvatar(notification.user)} />
            <p className='notification-message'>{notification.user} {notification.message}</p>
            <p className='notification-timestamp'>{convertTime(notification.timestamp)}</p>
          </div>
          </Link>
        )}
        {this.state.fetchedAll && (<div>No more notifications</div>)}
        <div id='notifications-bottom-window'>{scrollToLoad}</div>
      </div>
    )
  }
}

export const getNotifications = (from = -1, limit = 1000) => {
  return new Promise((resolve, reject) => {
    var name = localStorage.getItem('userName');
    getAccountHistory(name, from, limit)
    .then((res) => {
      try {
        var notifications = []
        var lastNotification = res[0][0];
        for (var result of res) {
          var number = result[0];
          var type = result[1].op[0];
          var action = result[1].op[1];
          var timestamp = result[1].timestamp;
          
          var rootUrl = '';
          var notification = '';
          var user = '';
          var message = '';
          var link = '';
          var other = {};
          switch(type) {
            case 'comment':
              if (action.author === name) {
                continue;
              }
              if (action.permlink.startsWith('re-')) {
                message = 'replied to your comment';
              }
              else {
                message = 'commented on your post';
              }
              rootUrl = `${action.parent_author}/${action.parent_permlink}#${action.author}/${action.permlink}`;
              link = `${urlPost}/${rootUrl}`;
              user = action.author;
              break;
              
            case 'create_claimed_account':
              //console.log(action);
              continue;
    
            case 'custom_json':
              continue;

            case 'delegate_vesting_shares':
              continue;
              /*
              if (action.from === name) {
                skip = true;
                break;
              }
              user = action.delegator;
              message = `delegated ${action.vesting_shares} to your account`;
              break;
              */
    
            case 'transfer':
              continue;
              /*
              if (action.from === name) {
                skip = true;
                break;
              }
              user = action.from;
              message = `sent you ${action.amount}`
              other = {
                amount: action.amount,
                message: action.memo
              }
              
              //console.log(`${action.from} sent you ${action.amount} : ${action.memo}`)
              //notifications.push(`${action.from} sent you ${action.amount} : ${action.memo}`)
              break;
              */

            case 'vote':
              if (action.voter === name) {
                continue;
              }
              if (action.permlink.startsWith('re-')) {
                message = 'voted on your comment';
              }
              else {
                message = 'voted on your post';
              }
              rootUrl = `${action.author}/${action.permlink}`
              link = `${urlPost}/${rootUrl}`
              user = action.voter;
              break;
    
            default:
              continue;
          }        
          notification = {
            href: link,
            user: user,
            message: message,
            timestamp: timestamp,
            number: number,
            type: type,
            other: other,
          }          
          notifications.push(notification)
        }
      //console.log(notifications)
      //array received from api is in reverse chronologial order - TODO sort based on time?
      notifications = notifications.reverse();
      /*
      //alternatively, sort based on their history index in the api
      notifications.sort((a, b) => {
        return b.number - a.number
      })
      */
      return resolve({notifications: notifications, lastNotification: lastNotification});
    }
    catch (ex) {
      return reject(ex);
    }
    })
    .catch(err => reject(err))
  })
}