import React from 'react';
import {Link} from 'react-router-dom';
import {Modal, Button} from 'react-bootstrap';
import {getSteemAvatar, getUrlProfile} from './constants';


export class PostVotesView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentVotesPage: 0,
      pagesOfVotes: [],
      showWeight: localStorage.getItem('showVoteWeight'),
    }
  }

  /**
   * Matches friends (people a user follows) and pushes them 
   * first in the array of active votes
   */
  pushFriendsFirst = (activeVotes) => {
    if (activeVotes.length === 0) return null;
    var votes = [...activeVotes]
    var friends = sessionStorage.getItem('followingList');
    var pushToFront = [];
    if (friends) {
      friends = JSON.parse(friends);
      //add yourself to list of friends to be pushed to the top of votes list
      if (localStorage.getItem('userName')) friends.unshift(localStorage.getItem('userName'));
      //checks for matches between friends and voters
      for (var i = 0; i < activeVotes.length; i++) {
        for (var j = 0; j < friends.length; j++) {
          if (votes[i] && votes[i].voter === friends[j]) {
            //removes friends and saves them for later
            pushToFront.push({voter: votes[i].voter, percent: votes[i].percent});
            votes.splice(i, 1);
          }
        }
      }
      if (pushToFront.length > 0) {
        //pushes friends to the front of the list
        for (var voter of pushToFront) {
          votes.unshift(voter)
        }
      }      
    }
    return votes;
  }

  setupPages = (votes) => {
    var pages = [];
    for (var i = 0; i < votes.length; i += 20) {
      var page = votes.slice(i, i+20);
      pages.push(page);
    }
    this.setState({pagesOfVotes: pages, votes: votes})
  }

  componentDidMount() {
    if (!this.props.votes || this.props.votes.length === 0) {
      this.setState({noVotes: true})
    }
    else {
      var votes = this.pushFriendsFirst(this.props.votes)
      this.setupPages(votes);
    }
  }

  render() {
    return(
      <Modal show={this.props.show} onHide={this.props.onHide} dialogClassName={`votes-popup ${this.props.className ? this.props.className: ''}`} >
        <Modal.Header closeButton>
          Votes on {this.props.author}'s {this.props.isComment ? 'comment' : 'post'} - {this.props.votes.length} total
        </Modal.Header>
        {this.state.noVotes || this.state.pagesOfVotes.length < 1? (
          <Modal.Body className='votes-popup-body'>
            <p>There are no votes yet</p>
          </Modal.Body>
        ) : (
        <Modal.Body className='votes-popup-body'>
          <p className='votes-popup-page-counter'>Page {this.state.currentVotesPage + 1}/{this.state.pagesOfVotes.length}</p>
          <ul className='votes-popup-list'>
            {this.state.pagesOfVotes[this.state.currentVotesPage].map((voter, index) =>
              <li className={`votes-popup-voter ${parseInt(voter.percent) === 0 ? 'unvoted' : ''}`} key={index}>
                <Link to={getUrlProfile(voter.voter)} title={`Visit ${voter.voter}'s profile`}>
                  <img className='votes-popup-voter-image' src={getSteemAvatar(voter.voter)}/>
                  <p>{voter.voter} {this.state.showWeight === 'true' ? `/ ${voter.percent / 100}%` : ''}</p>
                </Link>
              </li>
              )}
          </ul>            
          {this.state.currentVotesPage > 0 ? 
            <Button className='votes-popup-previous' onClick={() => this.setState({currentVotesPage: this.state.currentVotesPage-1})}>Previous</Button> 
            : ''}
          {this.state.currentVotesPage < this.state.pagesOfVotes.length -1? 
            <Button className='votes-popup-next' onClick={() => this.setState({currentVotesPage: this.state.currentVotesPage+1})}>Next</Button>
            : ''}
        </Modal.Body>
        )}
      </Modal>
    )
  }
}