export const convertToFloat = (floatString) => {
      var regex = new RegExp(/[^0-9,.]/g);
      try {
        var onlyNumbers = floatString.replace(regex, '');
        var converted = parseFloat(onlyNumbers);
      }
      catch (e) {
        var converted = floatString
      }
      return converted;
  }