export const minVoteWeight = 0;
export const maxVoteWeight = 100; //is 10000 (100%), gets multiplied by 100 in vote function
var voteWeightString = localStorage.getItem('voteWeight');
var parsedVoteWeight = parseInt(voteWeightString);
export var voteWeight = parsedVoteWeight ? parsedVoteWeight : maxVoteWeight;
export const getVoteWeight = () => {
  return parsedVoteWeight ? parsedVoteWeight : maxVoteWeight;
}
export const setVoteWeight = (weight) => {
    var parsedWeight = parseInt(weight);
    voteWeight = parsedWeight ? parsedWeight: maxVoteWeight;
}
export const voteSliderStep = 1;

export var feedMode = 'image';

export const deletedKeyword = 'DELETED';

export const steemAvatarUrl = 'https://steemitimages.com/u/';

export const getSteemAvatar = (account) => {
    return `https://steemitimages.com/u/${account}/avatar`
}

export const urlAppLinks = '/apps';

export const urlDiscover  = '/discover';

export const urlEditProfile = '/editprofile';

export const urlNewPost = '/createpost';

export const urlNotifications = '/notifications';

export const urlPost = '/post';
export const getUrlPost = (author, permlink) => {
    return `${urlPost}/${author}/${permlink}`
}

export const urlProfile = '/profile';
export const getUrlProfile = (account) => {
    return `${urlProfile}/${account}/`
}

export const urlSearch = '/search';
export const getUrlSearch = (query = '') => {
    return `${urlSearch}/${query}`
}

export const urlSettings = '/settings';

export const urlTag = '/tag';
export const getUrlTag = (tag, type = '') => {
  if (!tag) {
    return `/${type}`
  }
  if (tag === '___') {
    return `/all-tags/${type}`
  }
  return `${urlTag}/${tag}/${type}`
}

export const urlWallet = '/wallet';
export const getUrlWallet = (account) => {
    return `${urlWallet}/${account}`
}

export const urlFeedbackForm = 'https://docs.google.com/forms/d/e/1FAIpQLSedrpKsJItSvRgQhR0DfKQ5xK-MwEEtRtsWgjYn8Kfqkypb6Q/viewform';

export const urlRegisterLink = 'https://register.deegram.com';

//export const urlAboutDeegram = 'https://deegram.com/the-concept';

export const urlLicenses = '/webclient-licenses';


/* APIS */
export const steemConnectEditProfile = 'https://app.steemconnect.com/sign/profile-update';

export const deegramFeedApi = 'https://deegram.azurewebsites.net/api/feed/';
export const deegramTestFeedApi = 'https://deegram-test.azurewebsites.net/api/feed';

export const deegramImageApi = 'https://deegram.azurewebsites.net/api/images';
export const deegramTestImageApi = 'https://deegram.azurewebsites.net/api/images';

export const getDeegramImage = (imageId) => {
  return `${deegramImageApi}/${imageId}`
}

export const steemRateApi = 'https://api.cryptonator.com/api/ticker/STEEM-usd';
export const sbdRateApi = 'https://api.cryptonator.com/api/ticker/sbd-usd';