import {api} from './steemConnectApi';
import {createPermlink} from '../permLinkSetter';

/**
 * Creates posts on the blockchain using steemconnect.
 * @param {string} parentAuthor empty if new post, or author of post / parent comment if making a comment.
 * @param {string} parentPermlink first tag if new post, or permlink of post / parent comment if making a comment.
 * @param {string} author author of the post / comment.
 * @param {string} permlink empty if creating a new post / comment
 * @param {string} title title of the post / comment.
 * @param {string} body full body of the post / comment, including images in the post.
 * @param {string} jsonMetadata A stringified json object with tags, app name, other relevant info.
 */
export const postBlogForm = (parentAuthor, parentPermlink, author, permlink, title, body, jsonMetadata) => {
  return new Promise((resolve, reject) => {
    if (!permlink) permlink = createPermlink(author);
    if (parentAuthor) {
      permlink = `re-${permlink}`
    }
    api.comment(
      parentAuthor, //parentAuthor, skal være tom i et nytt innlegg, brukes i kommentarer for å bestemme hvem man kommenterer til
      parentPermlink, //parentPermlink, første tagen til posten. Permlink til innlegget man kommenterer på skal står her
      author,//author,//author, brukernavnet til den som kommenterer
      permlink,//permlink, url
      title, //title,
      body, //body,
      jsonMetadata, //jsonMetadata, tags, app etc
      (err, res) => {
        if (err) {
          reject(false);
        }
        if (res) {
          resolve(permlink);
        }
      });

    //comment options
    /*
    
  var extensions = 
  [ 
    [
      0, 
      {
        beneficiaries: 
        [ 
          {
            account: 'deegramofficial', weight: 1500
          }
        ] 
      }
    ] 
  ]

  const operations = ["comment_options", {
    author: 'tobiasalbert',
    permlink: 'puof3a',
    max_accepted_payout: '100000.000 SBD',
    percent_steem_dollars: 5000,
    allow_votes: true,
    allow_curation_rewards: true,
    extensions
    }
  ]
  api.broadcast(operations, (err, result) => {
    console.log(err, result);
  })*/
  })
}
