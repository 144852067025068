import {api} from './steemConnectApi';

/**
 * Current logged in account follows an account
 * @param {string} account account to follow
 */
export const followAccount = (account) => {
  return new Promise((resolve, reject) => {
    if (localStorage.getItem('userName')) {
      api.follow(localStorage.getItem('userName'), account, (err, res) => {
        if (err) {
          reject(err);
        }
        if (res) {
          resolve(res);
        }
      })
    }
    else {
      reject('Not logged in');
    }
  })
} 
/**
 * Current logged in account unfollows an account
 * @param {string} account account to unfollow
 */
export const unfollowAccount = (account) => {
  return new Promise((resolve, reject) => {
    if (localStorage.getItem('userName')) {
      api.unfollow(localStorage.getItem('userName'), account, (err, res) => {
        if (err) {
          reject(err)
        }
        if (res) {
          resolve(res);
        }
      })
    }
    else {
      reject('Not logged in');
    }
  })
}