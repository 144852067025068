import React from 'react';
import Cookies from 'universal-cookie';
import getUrlParameter from './urlParamGetter';
import { api } from './apiCalls/steemConnectApi';
import { isAuthenticated } from './authenticator';

export class CompleteSteemconnect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          
        }
        //this.setLoggedIn = this.setLoggedIn.bind(this);
        this.redirectToLastUrl = this.redirectToLastUrl.bind(this);
    }

    /*
    setLoggedIn() {
        const cookies = new Cookies();
        var url = new URL(document.location.href);
        var accessTokenUrl = url.searchParams.get('access_token');//getUrlParameter('access_token');
        var expiresIn = url.searchParams.get('expires_in');//getUrlParameter('expires_in');
        //console.log(accessTokenUrl, expiresIn)
        if (accessTokenUrl) {
            if (expiresIn) {
                expiresIn = parseInt(expiresIn);
                var date = new Date();
                //expires in is in seconds, setTime() uses milliseconds
                date = new Date(date.setTime(date.getTime() + expiresIn * 1000));
            }
            else {
                var date = new Date();
                date = new Date(date.setTime(date.getTime() + (7*24*60*60*1000)));
            }
            cookies.set('token', accessTokenUrl, {path: '/', expires: date});
            api.setAccessToken(accessTokenUrl);
            //console.log(accessTokenUrl);
            api.me((err, res) => {
                //console.log(err, res);
                if (res && !res.error) {
                    localStorage.setItem('userName', res.user);
                    localStorage.setItem('loggedIn', true);
                    this.redirectToLastUrl();
                }
            })
        }
        else {
            this.redirectToLastUrl();
        }
    }
    */

    setLoggedIn = (res) => {    
      localStorage.setItem('userName', res.user);
      localStorage.setItem('loggedIn', true);
      this.setState({isLoggedIn: true, userName: res.user})
      //redirect to current page, removes accessToken from url
      var redirectUrl = sessionStorage.getItem('currentUrl');
      if (redirectUrl) {
        sessionStorage.removeItem('currentUrl');
        document.location.href = redirectUrl;
      }
    }

    redirectToLastUrl() {
        var redirectUrl = localStorage.getItem('currentUrl');
        localStorage.setItem('currentUrl', '');
        localStorage.removeItem('currentUrl');
        redirectUrl = redirectUrl ? redirectUrl : 'https://deegram.com/';
        document.location.href = redirectUrl;
    }

    componentDidMount() {
      var isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);      
      var isAlsoMobile = window.matchMedia('only screen and (max-width: 767px').matches;
      if (isMobile || isAlsoMobile) {
        this.setState({isMobile: true})
      }
      else {
        isAuthenticated()
        .then(this.setLoggedIn)
        .catch(err => console.err(err))
      }
    }

    render() {
      if (this.state.isMobile) {
        return (
            <div style={{position: 'fixed', width: '100vw', height: '100vh', background: 'white', zIndex: 5000, overflow: 'hidden'}}>
              <h2 style={{color: 'white'}}>Success!</h2>
            </div>
        )
      }
      else {
        return (
          <div className='padding-top'>Success!</div>
        )
      }
    }
}