import React from 'react';
import {Popover, Button, OverlayTrigger} from 'react-bootstrap';

/* Class that creates a clickable question mark, with a popup with custom
title and text. Example use:
<HelpBoxes title='Example title' text='Example text'/>
Is currently only used when making posts - users can click to learn how to add tags
*/
export default class HelpBoxes extends React.Component {
  render() {
    const popoverHelpBox = (
      <Popover
        id='popoverBox'
        title={this.props.title}
        placement={this.props.placement ? this.props.placement : 'right'}
      >{this.props.text}</Popover>
    );
    return(
      <OverlayTrigger
        trigger='focus'
        placement={this.props.placement ? this.props.placement : 'right'}
        overlay={popoverHelpBox}
      >
        <Button className='helpbox-button'>
          <span className="glyphicon glyphicon-question-sign" aria-hidden="true"></span>
        </Button>
      </OverlayTrigger>
    )
  }
}
